.size(@size) {
    width: @size;
    height: @size;
}

.overlay(@pos: 0, @type: absolute) {
    position: @type;
    left: @pos;
    right: @pos;
    bottom: @pos;
    top: @pos;
}

.flex-center() {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-overflow() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.addButtonVariant(@color) {
    box-shadow: 0 2px 5px rgba(@color, 0.4);
    border: none;
}

.customizeScrollbars(@size: 5px, @thumb: #6ca8fa, @background: #fff, @visibility: visible) {
    // Customize scrollbars
    /* Let's get this party started */
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: @size;
        height: @size;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #fff;
        box-shadow: inset 1px 1px 0 rgba(black, 0.05);
        border-radius: 10px;
        background: @background;
        visibility: @visibility;
    }

    &:hover::-webkit-scrollbar-track {
        visibility: visible;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: @thumb;
        visibility: @visibility;
    }

    &:hover::-webkit-scrollbar-thumb {
        visibility: visible;
    }

    &::-webkit-scrollbar-thumb:window-inactive {
    }
}

.random(@min: 0, @max: @min+1, @int: 0) {
    .checkInt() {
        @getNum: `Math.random() * (@{max} - @{min} + @{int})`;
        @base: unit(`@{int} == 1 ? Math.floor(@{getNum}) : @{getNum}`);
    }
    .checkInt();
    @randNum: @base + @min;
}
