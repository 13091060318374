.ant-btn {
    border-color: transparent;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;

    &:not(.ant-btn-circle) {
        border-radius: @border-radius;
    }

    &:hover {
        border-color: transparent;
    }

    &.ant-btn-primary {
        &:disabled {
            border-color: transparent;
            background: rgba(#4361FF, 0.5);
            color: #fff;

        }
    }

    i {
        margin-right: 0.3rem;
        min-width: 1ch;
    }

    &.is-loading {
        .locked;
        transition: all 0s;
        opacity: 0.8;

        i {
            font-weight: @weight-bold;
        }
    }

    &.ant-btn-circle i {
        margin: 0 !important;
    }

    &.ant-btn-white {
    }

    &:not(.ant-btn-link):not(.ant-btn-text) {
        box-shadow: 0 2px 4px -1px rgba(#185b7e, 0.2), 0 -1px 0 rgba(#185b7e, 0.04);
    }

    &:not([disabled]) {
        &.ant-btn-primary {
            background: @primary;
            box-shadow: 0 3px 8px rgba(@primary, 0.3);

            &:hover {
                box-shadow: 0 6px 10px rgba(@primary, 0.4);
                background: lighten(@primary, 5%);
            }
        }

        &.ant-btn-cta {
            padding: 0.4rem 1rem;
            font-weight: 500;
            height: unset;
            border: none;
            color: #fff;
            background: @primary;
            .addButtonVariant(@primary);
        }

        &.ant-btn-success {
            box-shadow: 0 2px 4px 1px rgba(@success, 0.5);
            background: @success;
            border-color: @success;
            color: #fff;
        }
    }

    &.actions-btn {
        // margin-right: 0.2rem;
        position: relative;
        // background: transparent;
        // box-shadow: none;
        border: none;
    }

    &:not(.ant-btn-circle) {
        &.ant-btn-primary {
            &.ant-btn-lg {
                padding: 0.3rem 1.4rem;
            }
        }
    }

    &.ant-btn-square {
        i {
            margin: 0;
        }
    }

    &.ant-btn-link-danger {
        padding: 0;
        border: none;
        outline: none !important;
        box-shadow: none !important;
        background: none !important;
        color: @danger;

        &::before, &::after {
            display: none !important;
        }

        > span {
            padding: 0;
            border: none;
            outline: none !important;
            box-shadow: none !important;
            background: none !important;
        }
    }
}

// Todo: Merge with TaskLinkButtons (styles)
.ant-btn.btn-circle {
    .tiny-shadows;
    .size(@action-btn-size);
    background: #fff;
    border: none;
    font-size: 14px;
    margin-right: 0.3rem;
    padding: 0;

    &:not([disabled]) {
        &.action-edit {
            color: #8fa9c0;
        }

        &.action-approve {
            color: @success;

            &:hover {
                transform: scale(1.2) rotate(-15deg) translateY(-2px);
            }
        }
    }
}

.ant-form-item-control-input {
    .ant-btn-icon-only {
        box-shadow: none;
    }
}

.ant-notification-notice {
    max-height: 80vh;
}
.ant-notification-notice-close {
    padding-left: 5px;
    padding-right: 5px;
}

.btn-pulse {
    position: relative;

    &:after {
        content: "";
        border: 2px solid;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 35px;
        animation: sonar 1.5s ease infinite;
    }
    &.ant-btn-primary:after {
        border-color: @primary;
    }
    &.ant-btn-success:after {
        border-color: @success;
    }
}

.google-link-pulsating {
    position: relative;
    z-index: 1;
    display: inline-block;
    background: @primary !important;
    padding: 5px 10px !important;
    border-radius: 35px !important;
    a, .Ico__icon.primary {
        color: #fff !important;
    }



    &:after {
        content: "";
        border: 2px solid @primary;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 35px;
        animation: sonarSm 1.5s ease infinite;
        z-index: -1;
    }

}

.ant-btn-inline {
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    display: inline-flex !important;
    align-items: center !important;
    background: transparent !important;
    line-height: 1;
    font-weight: 400;
    height: auto;

    > i {
        background: transparent !important;

        &.danger {
            color: @danger;
        }
    }
}
