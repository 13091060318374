// Main
.tag,
.ant-tag {
    border-radius: 4px;
    font-weight: @weight-bold;
    line-height: 1;
    padding: 2px 3px;
    box-shadow: 0 1px 2px rgba(black, 0.1);
    background: #fafafa;
    font-size: 13px;
    color: #222;
    border: none;
    margin: 0;

    &.tag-dot {
        font-size: 9px;
        overflow: hidden;
        color: rgba(white, 0) !important;
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
}

.tag-muted {
    border: 1px solid rgba(grey, 0.4);
    background: #fff;
    color: grey;
}


.tagOutline(@color) {
     color: @color;
     box-shadow: 0 0 0 1px rgba(@color, 0.5);
}

.tag(@color) {
     .tag;
     color: #fff;
     background: @color;
     box-shadow: 0 2px 4px rgba(@color, 0.5);
}

.bonusPaymentTypes {
    &.status-1 {
         .tagOutline(@tag-black)
    }

    &.status-2 {
          .tagOutline(@tag-black)
    }

    &.status-3 {
          .tagOutline(@tag-black)
    }

}

.google-doc-link,
.jira-link {
     .tag-muted
}

// Statuses
.userRoles {
     .tag;

    &.role-1 {
         .tag(@tag-danger);
    }

    &.role-2 {
         .tag(@tag-info);
    }

    &.role-3 {
         .tag(@tag-mint);
    }
}

.PontConvertersCards__inner {
    display: flex;

    > *:last-child {
        .ant-tag {
            font-weight: @weight-bold;
        }
    }
}

.priceSettingStatuses,
.linkPlanStatuses {
    // Unverified
    font-weight: @weight-bold;
}

.referringLinkTypes {
     .tag-muted; // new
}

.referringLinkRelevanceStatuses {
     &.status-1  {
          .tag(@tag-warning);

     }
     &.status-2 {
          .tag(@tag-success);
     }
     &.status-3 {
          .tag(@tag-danger);
     }

     &.status-4 {
          .tag(@tag-danger);
     }

     &.status-5 {
          .tag(@tag-danger);
     }

     &.status-6 {
          .tag(@tag-warning);
     }
}

.taskStatus {
    &.status-1 {
         .tag(@tag-warning); // new
    }
    &.status-2 {
         .tag(@tag-primary); // new
    }
}
.outreachTaskTypes {
    &.type-1 {
         .tagOutline(@tag-info); // new
    }
    &.type-2 {
         .tagOutline(@tag-info); // new
    }
}

.referringLinkIssueStatus {
    &.status-1 {
         .tag(@tag-success); // new
    }
}

.ant-tag-green {
     .tag(@tag-success);
}

.TagResponseCode {
    font-size: 11px !important;

    &.code-20s {
         .tag(@tag-success);
    }
    &.code-40s {
         .tag(@tag-warning);
    }
    &.code-50s {
         .tag(@tag-danger);
    }
}

.tag-danger {
     .tag(@tag-danger);
}

.RefDomainsTable,
.TasksTable {
    &__status {
        .ant-tag {
            // @include flex-center;
            text-align: center;
            margin: 0 auto;
        }
    }
}

// 1 Draft
// 2 Active
// 3 Paused
// 4 Suspended
.referringDomainPenaltyStatuses,
.fileStatuses,
.webmasterStatuses,
.userStatuses,
.thematicConformityStatuses,
.submissionTypeStatuses,
.siteStatuses,
.priceSettingStatuses,
.linkAttributeStatuses,
.landingStatuses,
.donatedSiteStatuses,
.donatedLandingStatuses,
.organicnessStatuses,
.teamStatuses,
.anchorStatuses {
    &.status-1 {
         .tag(@tag-warning);
    }

    &.status-2 {
         .tag(@tag-success);
    }

    &.status-3 {
         .tag-muted;
    }

    &.status-4 {
         .tag(@tag-danger);
    }
}

.userStatuses {
    &.status-5 {
         .tag(@tag-warning);
    }
}

.submissionTypeFeaturesStatuses {
    &.status-1 {
         .tag(@tag-info);
    }

    &.status-2 {
         .tag;
        color: rgba(black, 0.5);
    }

    &.status-3 {
         .tag(@tag-success);
    }

    &.status-4 {
         .tag-muted;
    }
}

// 1:"Draft"
// 2:"Submitted"
// 3:"Noted"
// 4:"Discarded"
// 5:"Removed"
// 6:"Dismissed"

.feedbackStatuses {
    &.status-1 {
         .tag(@tag-warning);
    }

    &.status-2 {
         .tag(@tag-info);
    }

    &.status-3 {
         .tag(@tag-success);
    }

    &.status-4,
    &.status-6 {
         .tag(@tag-danger);
    }

    &.status-5 {
         .tag-muted;
    }
}

// 1 "Unverified"
// 2 "Pending"
// 3 "Paid"
// 4 "Cancel"
// 5 "Reverted"
// 6 "Declined"
// 7 "Discarded"
.financialPenaltyStatuses,
.bonusPaymentStatuses,
.attainmentPaymentStatuses {
    &.status-1 {
         .tag(@tag-warning);
    }

    &.status-2 {
         .tag(@tag-info);
    }

    &.status-3 {
         .tag(@tag-success);
    }

    &.status-4,
    &.status-5 {
         .tag-muted;
    }

    &.status-6,
    &.status-7 {
         .tag(@tag-danger);
    }
}

// 1:"Visible"
// 2:"Hidden"
.teamUserStatuses,
.messageStatuses,
.commentStatuses {
    &.status-1 {
         .tag(@tag-success);
    }

    &.status-2 {
         .tag(@tag-warning);
    }
}

// 1:"Active"
// 2:"Inactive"
.referringPageIssueStatuses,
.referringLinkIssueStatuses,
.referringDomainIssueStatuses {
    &.status-1 {
         .tag(@tag-success);
    }

    &.status-2 {
         .tag(@tag-danger);
    }
}

.paymentOptionStatuses {
    &.status-1 {
         .tag(@tag-danger);
    }

    &.status-2 {
         .tag(@tag-success);
    }
}

// 1:"Unresolved"
// 2:"Resolved"

.issueStatuses {
    &.status-1 {
         .tag(@tag-danger);
    }

    &.status-2 {
         .tag(@tag-success);
    }
}

// 1:"Draft"
// 2:"To Do"
// 3:"In Progress"
// 4:"Completed"
// 5:"Canceled"
// 6:"Removed"
// 7:"Paused"
.linkPlanStatuses {
    &.status-1 {
         .tag(@tag-warning);
    }

    &.status-2 {
         .tag(@tag-info);
    }
    &.status-3 {
         .tag(@tag-mint);
    }

    &.status-4 {
         .tag(@tag-success);
    }

    &.status-5,
    &.status-6 {
         .tag(@tag-danger);
    }

    &.status-7 {
         .tag-muted;
    }
}

// 1:"Outstanding"
// 2:"Good"
// 3:"Mediocre"
// 4:"Poor"
// 5:"Inorganic"
.thematicConformityLevels,
.organicnessLevels {
    &.status-1 {
         .tag(@tag-success);
    }

    &.status-2 {
         .tag(@tag-info);
    }

    &.status-3 {
         .tag-muted;
    }

    &.status-4 {
         .tag(@tag-warning);
    }

    &.status-5 {
         .tag(@tag-danger);
    }
}

// 1(pin):"Unverified"
// 2(pin):"Discarded"
// 3(pin):"Declined"
// 4(pin):"Active"
// 5(pin):"Relinquished"
// 6(pin):"Paused"
// 7(pin):"Suspended"
.paymentDetailStatuses {
    &.status-1 {
         .tag(@tag-warning);
    }

    &.status-2,
    &.status-3,
    &.status-7 {
         .tag(@tag-danger);
    }

    &.status-4 {
         .tag(@tag-success);
    }

    &.status-5,
    &.status-6 {
         .tag-muted;
    }
}

// 1(pin):"New"
// 2(pin):"Taken"
// 3(pin):"Dismissed"
.referringDomainStatuses {
    &.status-1 {
         .tag(@tag-success);
    }

    &.status-2 {
         .tag(@tag-warning);
    }

    &.status-3 {
         .tag(@tag-danger);
    }
}

// 1(pin):"New"
// 2(pin):"Live"
// 3(pin):"Just Lost"
// 4(pin):"Lost"
// 5(pin):"Restored"
.referringLinkStatuses {
    &.status-1 {
         .tag(@tag-warning);
    }

    &.status-2 {
         .tag(@tag-success);
    }

    &.status-3 {
         .tag(@tag-danger);
    }

    &.status-4 {
         .tag(@tag-danger);
    }

    &.status-5 {
         .tag(@tag-success);
    }
}

// 1(pin):"Required"
// 2(pin):"In Progress"
// 3(pin):"Received"
// 4(pin):"To Adjust"
// 5(pin):"Canceled"
// 6(pin):"Declined"
// 7(pin):"Discarded"
// 8(pin):"Futile"
.customEffortStatuses,
.submissionContentStatuses {
    &.status-1 {
         .tag(@tag-warning);
    }

    &.status-2 {
         .tag(@tag-mint);
    }

    &.status-3 {
         .tag(@tag-success);
    }

    &.status-4 {
         .tag(@tag-warning);
    }

    &.status-5,
    &.status-8 {
         .tag-muted;
    }

    &.status-6,
    &.status-7 {
         .tag(@tag-danger);
    }
}

// 1(pin):"Unverified"
// 2(pin):"Pending"
// 3(pin):"Paid"
// 4(pin):"Refunded"
// 5(pin):"Canceled"
// 6(pin):"Declined"
// 7(pin):"Discarded"
.submissionPaymentStatuses {
    &.status-1 {
         .tag(@tag-warning);
    }

    &.status-2 {
         .tag(@tag-info);
    }

    &.status-3 {
         .tag(@tag-success);
    }

    &.status-4 {
         .tag;
    }

    &.status-5 {
         .tag-muted;
    }

    &.status-6,
    &.status-7 {
         .tag(@tag-danger);
    }
}

// 1(pin):"Link Exchange"
// 2(pin):"Content Exchange"
// 3(pin):"Guest Posting"
// 4(pin):"Free Acquisition"
// 5(pin):"Paid Link"
// 6(pin):"Other"

.submissionTypeNatures {
    &.status-1 {
         .tag(@tag-warning);
    }

    &.status-2 {
         .tag(@tag-info);
    }

    &.status-3 {
         .tag(@tag-success);
    }

    &.status-4 {
         .tag(@tag-mint);
    }

    &.status-5 {
         .tag-muted;
    }

    &.status-6 {
         .tag(@tag-danger);
    }
}

.attainmentPaymentBatchStatuses {
     &.status-1 {
          .tag(@tag-warning);
     }

     &.status-2 {
          .tag(@tag-success);
     }

     &.status-3 {
          .tag-muted;
     }
}

.referringDomainTypes {
     .tag-muted;
}

// 1(pin):"Unverified"
// 2(pin):"Verified"
// 3(pin):"Declined"
.taskDonatedLandingStatuses,
.taskSubmissionPriceStatuses,
.taskSubmissionTypeStatuses,
.taskReferringPageStatuses,
.taskReferringLinkStatuses,
.taskReferringDomainStatuses {
    &.status-1 {
         .tag(@tag-warning);
    }

    &.status-2 {
         .tag(@tag-success);
    }

    &.status-3 {
         .tag(@tag-danger);
    }
}

// 1(pin):"Draft"
// 2(pin):"To Do"
// 3(pin):"In Progress"
// 4(pin):"Submitted"
// 5(pin):"Completed"
// 6(pin):"Canceled"
// 7(pin):"Paused"
// 8(pin):"Removed"
.taskStatuses {
    &.status-1 {
         .tag(@tag-warning);
    }

    &.status-2 {
         .tag(@tag-info);
    }

    &.status-3 {
         .tag(@tag-mint);
    }

    &.status-4 {
         .tag(@tag-success);
    }

    &.status-5 {
         .tag(@tag-success);
    }

    &.status-6,
    &.status-7 {
         .tag-muted;
    }

    &.status-8 {
         .tag(@tag-danger);
    }

    &.status-9 {
         .tag(@tag-info);
    }
}

// 1(pin):"Pending"
// 2(pin):"Organized"
// 3(pin):"Passed"
// 4(pin):"Failed"
// 5(pin):"Not Imposed"

.userOnboardingStages {
    &.status-1 {
         .tag(@tag-warning);
    }

    &.status-2 {
         .tag(@tag-info);
    }

    &.status-3 {
         .tag(@tag-success);
    }

    &.status-4 {
         .tag(@tag-danger);
    }

    &.status-5 {
         .tag-muted;
    }

}


.referringDomainContactStages {
     &.status-1 {
          .tag-muted;

     }

     &.status-2 {
          .tag(@tag-mint);

     }
     &.status-3 {
          .tag(@tag-info);
     }

     &.status-4 {
          .tag(@tag-info);
     }


     &.status-5 {
          .tag(@tag-success);
     }

     &.status-6 {
          .tag(@tag-danger);
     }
     &.status-7 {
          .tag(@tag-warning);
     }

     &.status-8 {
          .tag(@tag-danger);
     }

     &.status-9 {
          .tag(@tag-success);
     }
}


.landingTypes {
     .tagOutline(@tag-black)
}

.taskTypes {
     .tagOutline(@tag-black)
}

.submissionContentTypes,
.attainmentPaymentTypes,
.attainmentPaymentBonusTypes {
     .tagOutline(@tag-black)
}

.taskRefDomainDangerTag {
     .tagOutline(@tag-danger);
     background-color: #fff;
}
