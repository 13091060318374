@keyframes zoomIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes like {
    from {
        transform: scale(0.9) rotate(-5);
    }

    70% {
        transform: scale(1.3) rotate(-15deg) translateY(-2px);
    }

    to {
        transform: scale(1);
    }
}

@keyframes fadeInLeftUp {
    from {
        opacity: 0;
        transform: translate(-100px, 100px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-30px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(100px);
    }
}

@keyframes rotateNext {
    from {
        transform: rotate(45deg);
    }

    to {
        transform: rotate(0);
    }
}

@keyframes rotatePrev {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(45deg);
    }
}

@keyframes pulse {
    from {
        transform: scale(1);
        opacity: 0;
    }

    40% {
        transform: scale(1.2);
        opacity: 1;
    }

    to {
        transform: scale(1.4);
        opacity: 0;
    }
}

@keyframes jelloHorizontal {
    0% {
        transform: scale3d(1, 1, 1);
    }

    30% {
        transform: scale3d(1.15, 0.75, 1);
    }

    40% {
        transform: scale3d(0.75, 1.25, 1);
    }

    50% {
        transform: scale3d(1.15, 0.85, 1);
    }

    65% {
        transform: scale3d(0.95, 1.05, 1);
    }

    75% {
        transform: scale3d(1.05, 0.95, 1);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

.rotate-next {
    animation: rotateNext 0.2s linear;
}

.rotate-prev {
    animation: rotatePrev 0.2s linear reverse;
}

.zoomIn {
    animation: zoomIn 0.4s ease-in-out both;
}

.fadeIn {
    animation: fadeIn 0.4s ease-in-out both;
}

.fadeOut {
    animation: fadeOut 0.4s ease-in-out both;
}

.fadeInUp {
    animation: fadeInUp 0.4s ease-in-out both;
}

.fadeInLeft {
    animation: fadeInLeft 0.4s ease-in-out both;
}

.spin-fast {
    animation: fa-spin 0.65s linear infinite;
}

.fa-spin {
    animation-duration: 0.4s;
}

@keyframes sonar {
    from {
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(1.5);
    }
}


@keyframes sonarSm {
    from {
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(1.1);
    }
}
