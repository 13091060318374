// Override variables
@import "../../styles/core/_app-variables";
@import "../../styles/core/_mixins";
.wrapper {
    overflow: hidden;
    min-height: 100vh;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.confetti {
    position: absolute;
    will-change: transform;
}
@keyframes emoji {
    from {
        transform: translate(calc(80px - 50%), -90%) scale(0.95);
    }

    30% {
        opacity: 1;
        transform: translate(calc(80px - 50%), -50%) scale(1.1);
    }

    99% {
        transform: translate(calc(80px - 50%), -90%) scale(1);
    }
    to {
        opacity: 0;
    }
}
.emoji {
    position: fixed;
    display: block;
    top: 100px;
    left: 50%;
    z-index: 100;
    font-size: 60px;
    line-height: 0;
    animation: emoji 1s linear both;
    will-change: transform opacity;
}

// TODO: Rewrite this to less
// $colors: (#d13447, #ffbf00, #263672);


// @for $i from 0 through 150 {
//     $w: random(15);
//     $l: random(100);
//     .confetti-#{$i} {
//         width: #{$w}px;
//         height: #{$w * 0.4}px;
//         background-color: nth($colors, random(3));
//         top: -10%;
//         left: unquote($l + "%");
//         opacity: random() + 0.5;
//         transform: rotate(#{random() * 360}deg);
//         animation: drop-#{$i} unquote(2 + random() + "s") unquote(random() + "s");
//         will-change: left, top;
//     }

//     @keyframes drop-#{$i} {
//         100% {
//             top: 110%;
//             left: unquote($l + random(15) + "%");
//         }
//     }
// }

.confetti-0 {
	width: 5px;
	height: 2px;
	background-color: #263672;
	top: -10%;
	left: 58%;
	opacity: 0.64758;
	transform: rotate(336.24153deg);
	animation: drop-0 2.45145s 0.89029s;
	will-change: left, top;
}

@keyframes drop-0 {
	100% {
		top: 110%;
		left: 72%;
	}
}

.confetti-1 {
	width: 13px;
	height: 5.2px;
	background-color: #d13447;
	top: -10%;
	left: 90%;
	opacity: 1.08218;
	transform: rotate(94.77876deg);
	animation: drop-1 2.6302s 0.33273s;
	will-change: left, top;
}

@keyframes drop-1 {
	100% {
		top: 110%;
		left: 94%;
	}
}

.confetti-2 {
	width: 1px;
	height: 0.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 12%;
	opacity: 1.22712;
	transform: rotate(146.91341deg);
	animation: drop-2 2.84705s 0.12493s;
	will-change: left, top;
}

@keyframes drop-2 {
	100% {
		top: 110%;
		left: 17%;
	}
}

.confetti-3 {
	width: 13px;
	height: 5.2px;
	background-color: #ffbf00;
	top: -10%;
	left: 65%;
	opacity: 0.56178;
	transform: rotate(61.54798deg);
	animation: drop-3 2.18025s 0.56404s;
	will-change: left, top;
}

@keyframes drop-3 {
	100% {
		top: 110%;
		left: 72%;
	}
}

.confetti-4 {
	width: 12px;
	height: 4.8px;
	background-color: #263672;
	top: -10%;
	left: 31%;
	opacity: 1.22687;
	transform: rotate(220.85987deg);
	animation: drop-4 2.56124s 0.64317s;
	will-change: left, top;
}

@keyframes drop-4 {
	100% {
		top: 110%;
		left: 42%;
	}
}

.confetti-5 {
	width: 15px;
	height: 6px;
	background-color: #ffbf00;
	top: -10%;
	left: 81%;
	opacity: 1.45173;
	transform: rotate(167.15872deg);
	animation: drop-5 2.16981s 0.35524s;
	will-change: left, top;
}

@keyframes drop-5 {
	100% {
		top: 110%;
		left: 90%;
	}
}

.confetti-6 {
	width: 6px;
	height: 2.4px;
	background-color: #263672;
	top: -10%;
	left: 75%;
	opacity: 1.35353;
	transform: rotate(5.66608deg);
	animation: drop-6 2.65349s 0.46243s;
	will-change: left, top;
}

@keyframes drop-6 {
	100% {
		top: 110%;
		left: 85%;
	}
}

.confetti-7 {
	width: 1px;
	height: 0.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 20%;
	opacity: 1.29304;
	transform: rotate(26.74793deg);
	animation: drop-7 2.57251s 0.63055s;
	will-change: left, top;
}

@keyframes drop-7 {
	100% {
		top: 110%;
		left: 34%;
	}
}

.confetti-8 {
	width: 12px;
	height: 4.8px;
	background-color: #263672;
	top: -10%;
	left: 56%;
	opacity: 0.68778;
	transform: rotate(69.96745deg);
	animation: drop-8 2.03302s 0.53605s;
	will-change: left, top;
}

@keyframes drop-8 {
	100% {
		top: 110%;
		left: 61%;
	}
}

.confetti-9 {
	width: 15px;
	height: 6px;
	background-color: #263672;
	top: -10%;
	left: 9%;
	opacity: 0.55237;
	transform: rotate(95.07183deg);
	animation: drop-9 2.64337s 0.04028s;
	will-change: left, top;
}

@keyframes drop-9 {
	100% {
		top: 110%;
		left: 15%;
	}
}

.confetti-10 {
	width: 9px;
	height: 3.6px;
	background-color: #d13447;
	top: -10%;
	left: 59%;
	opacity: 1.13322;
	transform: rotate(209.65527deg);
	animation: drop-10 2.55602s 0.81826s;
	will-change: left, top;
}

@keyframes drop-10 {
	100% {
		top: 110%;
		left: 61%;
	}
}

.confetti-11 {
	width: 14px;
	height: 5.6px;
	background-color: #263672;
	top: -10%;
	left: 60%;
	opacity: 0.5502;
	transform: rotate(203.11205deg);
	animation: drop-11 2.01707s 0.13786s;
	will-change: left, top;
}

@keyframes drop-11 {
	100% {
		top: 110%;
		left: 70%;
	}
}

.confetti-12 {
	width: 8px;
	height: 3.2px;
	background-color: #d13447;
	top: -10%;
	left: 85%;
	opacity: 0.99668;
	transform: rotate(225.83157deg);
	animation: drop-12 2.05724s 0.84193s;
	will-change: left, top;
}

@keyframes drop-12 {
	100% {
		top: 110%;
		left: 94%;
	}
}

.confetti-13 {
	width: 6px;
	height: 2.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 2%;
	opacity: 1.49424;
	transform: rotate(257.19795deg);
	animation: drop-13 2.87341s 0.37377s;
	will-change: left, top;
}

@keyframes drop-13 {
	100% {
		top: 110%;
		left: 14%;
	}
}

.confetti-14 {
	width: 8px;
	height: 3.2px;
	background-color: #263672;
	top: -10%;
	left: 76%;
	opacity: 0.78442;
	transform: rotate(228.89203deg);
	animation: drop-14 2.70493s 0.7991s;
	will-change: left, top;
}

@keyframes drop-14 {
	100% {
		top: 110%;
		left: 89%;
	}
}

.confetti-15 {
	width: 3px;
	height: 1.2px;
	background-color: #ffbf00;
	top: -10%;
	left: 42%;
	opacity: 1.40629;
	transform: rotate(341.05289deg);
	animation: drop-15 2.24958s 0.9162s;
	will-change: left, top;
}

@keyframes drop-15 {
	100% {
		top: 110%;
		left: 51%;
	}
}

.confetti-16 {
	width: 9px;
	height: 3.6px;
	background-color: #ffbf00;
	top: -10%;
	left: 45%;
	opacity: 0.71859;
	transform: rotate(43.18887deg);
	animation: drop-16 2.77076s 0.36072s;
	will-change: left, top;
}

@keyframes drop-16 {
	100% {
		top: 110%;
		left: 55%;
	}
}

.confetti-17 {
	width: 10px;
	height: 4px;
	background-color: #263672;
	top: -10%;
	left: 48%;
	opacity: 0.56075;
	transform: rotate(153.10801deg);
	animation: drop-17 2.74103s 0.87533s;
	will-change: left, top;
}

@keyframes drop-17 {
	100% {
		top: 110%;
		left: 56%;
	}
}

.confetti-18 {
	width: 4px;
	height: 1.6px;
	background-color: #d13447;
	top: -10%;
	left: 63%;
	opacity: 0.86719;
	transform: rotate(59.9586deg);
	animation: drop-18 2.9866s 0.63602s;
	will-change: left, top;
}

@keyframes drop-18 {
	100% {
		top: 110%;
		left: 75%;
	}
}

.confetti-19 {
	width: 2px;
	height: 0.8px;
	background-color: #263672;
	top: -10%;
	left: 29%;
	opacity: 1.32892;
	transform: rotate(348.00534deg);
	animation: drop-19 2.82824s 0.41417s;
	will-change: left, top;
}

@keyframes drop-19 {
	100% {
		top: 110%;
		left: 39%;
	}
}

.confetti-20 {
	width: 15px;
	height: 6px;
	background-color: #d13447;
	top: -10%;
	left: 73%;
	opacity: 0.66317;
	transform: rotate(181.27335deg);
	animation: drop-20 2.64855s 0.16071s;
	will-change: left, top;
}

@keyframes drop-20 {
	100% {
		top: 110%;
		left: 76%;
	}
}

.confetti-21 {
	width: 7px;
	height: 2.8px;
	background-color: #263672;
	top: -10%;
	left: 91%;
	opacity: 1.49909;
	transform: rotate(85.89137deg);
	animation: drop-21 2.2455s 0.76225s;
	will-change: left, top;
}

@keyframes drop-21 {
	100% {
		top: 110%;
		left: 103%;
	}
}

.confetti-22 {
	width: 7px;
	height: 2.8px;
	background-color: #ffbf00;
	top: -10%;
	left: 96%;
	opacity: 0.76192;
	transform: rotate(338.54993deg);
	animation: drop-22 2.26015s 0.97228s;
	will-change: left, top;
}

@keyframes drop-22 {
	100% {
		top: 110%;
		left: 97%;
	}
}

.confetti-23 {
	width: 10px;
	height: 4px;
	background-color: #263672;
	top: -10%;
	left: 97%;
	opacity: 0.66494;
	transform: rotate(72.86868deg);
	animation: drop-23 2.99829s 0.10804s;
	will-change: left, top;
}

@keyframes drop-23 {
	100% {
		top: 110%;
		left: 99%;
	}
}

.confetti-24 {
	width: 9px;
	height: 3.6px;
	background-color: #263672;
	top: -10%;
	left: 87%;
	opacity: 0.71734;
	transform: rotate(254.22977deg);
	animation: drop-24 2.31118s 0.2446s;
	will-change: left, top;
}

@keyframes drop-24 {
	100% {
		top: 110%;
		left: 102%;
	}
}

.confetti-25 {
	width: 10px;
	height: 4px;
	background-color: #263672;
	top: -10%;
	left: 67%;
	opacity: 1.06086;
	transform: rotate(287.05116deg);
	animation: drop-25 2.20106s 0.22936s;
	will-change: left, top;
}

@keyframes drop-25 {
	100% {
		top: 110%;
		left: 79%;
	}
}

.confetti-26 {
	width: 2px;
	height: 0.8px;
	background-color: #d13447;
	top: -10%;
	left: 65%;
	opacity: 1.23618;
	transform: rotate(183.9587deg);
	animation: drop-26 2.55878s 0.58239s;
	will-change: left, top;
}

@keyframes drop-26 {
	100% {
		top: 110%;
		left: 67%;
	}
}

.confetti-27 {
	width: 13px;
	height: 5.2px;
	background-color: #263672;
	top: -10%;
	left: 11%;
	opacity: 1.24847;
	transform: rotate(80.69557deg);
	animation: drop-27 2.18099s 0.59416s;
	will-change: left, top;
}

@keyframes drop-27 {
	100% {
		top: 110%;
		left: 12%;
	}
}

.confetti-28 {
	width: 9px;
	height: 3.6px;
	background-color: #d13447;
	top: -10%;
	left: 42%;
	opacity: 0.96368;
	transform: rotate(231.65863deg);
	animation: drop-28 2.33197s 0.14909s;
	will-change: left, top;
}

@keyframes drop-28 {
	100% {
		top: 110%;
		left: 44%;
	}
}

.confetti-29 {
	width: 15px;
	height: 6px;
	background-color: #263672;
	top: -10%;
	left: 15%;
	opacity: 1.33344;
	transform: rotate(348.80063deg);
	animation: drop-29 2.91578s 0.83441s;
	will-change: left, top;
}

@keyframes drop-29 {
	100% {
		top: 110%;
		left: 30%;
	}
}

.confetti-30 {
	width: 8px;
	height: 3.2px;
	background-color: #263672;
	top: -10%;
	left: 43%;
	opacity: 0.51162;
	transform: rotate(344.31262deg);
	animation: drop-30 2.18299s 0.2715s;
	will-change: left, top;
}

@keyframes drop-30 {
	100% {
		top: 110%;
		left: 47%;
	}
}

.confetti-31 {
	width: 10px;
	height: 4px;
	background-color: #d13447;
	top: -10%;
	left: 57%;
	opacity: 1.08091;
	transform: rotate(10.06127deg);
	animation: drop-31 2.59919s 0.86498s;
	will-change: left, top;
}

@keyframes drop-31 {
	100% {
		top: 110%;
		left: 69%;
	}
}

.confetti-32 {
	width: 11px;
	height: 4.4px;
	background-color: #263672;
	top: -10%;
	left: 69%;
	opacity: 1.41001;
	transform: rotate(176.18685deg);
	animation: drop-32 2.70704s 0.23019s;
	will-change: left, top;
}

@keyframes drop-32 {
	100% {
		top: 110%;
		left: 77%;
	}
}

.confetti-33 {
	width: 4px;
	height: 1.6px;
	background-color: #d13447;
	top: -10%;
	left: 43%;
	opacity: 0.87247;
	transform: rotate(286.88241deg);
	animation: drop-33 2.22699s 0.15943s;
	will-change: left, top;
}

@keyframes drop-33 {
	100% {
		top: 110%;
		left: 51%;
	}
}

.confetti-34 {
	width: 2px;
	height: 0.8px;
	background-color: #ffbf00;
	top: -10%;
	left: 63%;
	opacity: 0.69978;
	transform: rotate(327.56228deg);
	animation: drop-34 2.34975s 0.00439s;
	will-change: left, top;
}

@keyframes drop-34 {
	100% {
		top: 110%;
		left: 76%;
	}
}

.confetti-35 {
	width: 1px;
	height: 0.4px;
	background-color: #d13447;
	top: -10%;
	left: 7%;
	opacity: 0.63333;
	transform: rotate(143.06399deg);
	animation: drop-35 2.80552s 0.58368s;
	will-change: left, top;
}

@keyframes drop-35 {
	100% {
		top: 110%;
		left: 17%;
	}
}

.confetti-36 {
	width: 9px;
	height: 3.6px;
	background-color: #263672;
	top: -10%;
	left: 49%;
	opacity: 0.85232;
	transform: rotate(255.70908deg);
	animation: drop-36 2.45391s 0.99777s;
	will-change: left, top;
}

@keyframes drop-36 {
	100% {
		top: 110%;
		left: 51%;
	}
}

.confetti-37 {
	width: 3px;
	height: 1.2px;
	background-color: #263672;
	top: -10%;
	left: 44%;
	opacity: 0.95268;
	transform: rotate(352.93721deg);
	animation: drop-37 2.15387s 0.49022s;
	will-change: left, top;
}

@keyframes drop-37 {
	100% {
		top: 110%;
		left: 59%;
	}
}

.confetti-38 {
	width: 5px;
	height: 2px;
	background-color: #ffbf00;
	top: -10%;
	left: 41%;
	opacity: 1.35517;
	transform: rotate(258.07014deg);
	animation: drop-38 2.47315s 0.27454s;
	will-change: left, top;
}

@keyframes drop-38 {
	100% {
		top: 110%;
		left: 53%;
	}
}

.confetti-39 {
	width: 5px;
	height: 2px;
	background-color: #d13447;
	top: -10%;
	left: 38%;
	opacity: 0.78179;
	transform: rotate(298.65764deg);
	animation: drop-39 2.12555s 0.22074s;
	will-change: left, top;
}

@keyframes drop-39 {
	100% {
		top: 110%;
		left: 49%;
	}
}

.confetti-40 {
	width: 5px;
	height: 2px;
	background-color: #263672;
	top: -10%;
	left: 9%;
	opacity: 0.71161;
	transform: rotate(246.08365deg);
	animation: drop-40 2.19546s 0.5412s;
	will-change: left, top;
}

@keyframes drop-40 {
	100% {
		top: 110%;
		left: 22%;
	}
}

.confetti-41 {
	width: 9px;
	height: 3.6px;
	background-color: #263672;
	top: -10%;
	left: 73%;
	opacity: 0.63156;
	transform: rotate(27.4811deg);
	animation: drop-41 2.93096s 0.13534s;
	will-change: left, top;
}

@keyframes drop-41 {
	100% {
		top: 110%;
		left: 82%;
	}
}

.confetti-42 {
	width: 14px;
	height: 5.6px;
	background-color: #d13447;
	top: -10%;
	left: 14%;
	opacity: 0.96873;
	transform: rotate(135.96278deg);
	animation: drop-42 2.73474s 0.09844s;
	will-change: left, top;
}

@keyframes drop-42 {
	100% {
		top: 110%;
		left: 16%;
	}
}

.confetti-43 {
	width: 8px;
	height: 3.2px;
	background-color: #263672;
	top: -10%;
	left: 41%;
	opacity: 1.08775;
	transform: rotate(95.70801deg);
	animation: drop-43 2.69447s 0.50319s;
	will-change: left, top;
}

@keyframes drop-43 {
	100% {
		top: 110%;
		left: 48%;
	}
}

.confetti-44 {
	width: 13px;
	height: 5.2px;
	background-color: #263672;
	top: -10%;
	left: 20%;
	opacity: 1.42148;
	transform: rotate(176.13452deg);
	animation: drop-44 2.53365s 0.5448s;
	will-change: left, top;
}

@keyframes drop-44 {
	100% {
		top: 110%;
		left: 23%;
	}
}

.confetti-45 {
	width: 6px;
	height: 2.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 15%;
	opacity: 1.38616;
	transform: rotate(190.77416deg);
	animation: drop-45 2.7358s 0.77236s;
	will-change: left, top;
}

@keyframes drop-45 {
	100% {
		top: 110%;
		left: 19%;
	}
}

.confetti-46 {
	width: 14px;
	height: 5.6px;
	background-color: #ffbf00;
	top: -10%;
	left: 100%;
	opacity: 1.27052;
	transform: rotate(107.20674deg);
	animation: drop-46 2.37895s 0.85385s;
	will-change: left, top;
}

@keyframes drop-46 {
	100% {
		top: 110%;
		left: 107%;
	}
}

.confetti-47 {
	width: 11px;
	height: 4.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 54%;
	opacity: 0.75799;
	transform: rotate(205.40204deg);
	animation: drop-47 2.12562s 0.97424s;
	will-change: left, top;
}

@keyframes drop-47 {
	100% {
		top: 110%;
		left: 63%;
	}
}

.confetti-48 {
	width: 5px;
	height: 2px;
	background-color: #ffbf00;
	top: -10%;
	left: 31%;
	opacity: 0.60129;
	transform: rotate(101.76869deg);
	animation: drop-48 2.34522s 0.10211s;
	will-change: left, top;
}

@keyframes drop-48 {
	100% {
		top: 110%;
		left: 45%;
	}
}

.confetti-49 {
	width: 9px;
	height: 3.6px;
	background-color: #ffbf00;
	top: -10%;
	left: 30%;
	opacity: 0.79552;
	transform: rotate(85.9537deg);
	animation: drop-49 2.27772s 0.6535s;
	will-change: left, top;
}

@keyframes drop-49 {
	100% {
		top: 110%;
		left: 39%;
	}
}

.confetti-50 {
	width: 3px;
	height: 1.2px;
	background-color: #263672;
	top: -10%;
	left: 9%;
	opacity: 1.46915;
	transform: rotate(251.59316deg);
	animation: drop-50 2.02673s 0.46547s;
	will-change: left, top;
}

@keyframes drop-50 {
	100% {
		top: 110%;
		left: 19%;
	}
}

.confetti-51 {
	width: 9px;
	height: 3.6px;
	background-color: #d13447;
	top: -10%;
	left: 37%;
	opacity: 0.94805;
	transform: rotate(254.31944deg);
	animation: drop-51 2.52474s 0.25655s;
	will-change: left, top;
}

@keyframes drop-51 {
	100% {
		top: 110%;
		left: 38%;
	}
}

.confetti-52 {
	width: 7px;
	height: 2.8px;
	background-color: #ffbf00;
	top: -10%;
	left: 3%;
	opacity: 0.6013;
	transform: rotate(259.76865deg);
	animation: drop-52 2.94258s 0.9525s;
	will-change: left, top;
}

@keyframes drop-52 {
	100% {
		top: 110%;
		left: 14%;
	}
}

.confetti-53 {
	width: 14px;
	height: 5.6px;
	background-color: #d13447;
	top: -10%;
	left: 84%;
	opacity: 1.45209;
	transform: rotate(353.80739deg);
	animation: drop-53 2.07061s 0.95091s;
	will-change: left, top;
}

@keyframes drop-53 {
	100% {
		top: 110%;
		left: 88%;
	}
}

.confetti-54 {
	width: 7px;
	height: 2.8px;
	background-color: #263672;
	top: -10%;
	left: 72%;
	opacity: 1.42995;
	transform: rotate(142.78646deg);
	animation: drop-54 2.17403s 0.87691s;
	will-change: left, top;
}

@keyframes drop-54 {
	100% {
		top: 110%;
		left: 87%;
	}
}

.confetti-55 {
	width: 12px;
	height: 4.8px;
	background-color: #263672;
	top: -10%;
	left: 23%;
	opacity: 0.53551;
	transform: rotate(12.94915deg);
	animation: drop-55 2.82053s 0.76475s;
	will-change: left, top;
}

@keyframes drop-55 {
	100% {
		top: 110%;
		left: 27%;
	}
}

.confetti-56 {
	width: 15px;
	height: 6px;
	background-color: #d13447;
	top: -10%;
	left: 38%;
	opacity: 1.23149;
	transform: rotate(289.0952deg);
	animation: drop-56 2.25369s 0.51053s;
	will-change: left, top;
}

@keyframes drop-56 {
	100% {
		top: 110%;
		left: 42%;
	}
}

.confetti-57 {
	width: 10px;
	height: 4px;
	background-color: #ffbf00;
	top: -10%;
	left: 96%;
	opacity: 1.25082;
	transform: rotate(211.9774deg);
	animation: drop-57 2.17932s 0.31132s;
	will-change: left, top;
}

@keyframes drop-57 {
	100% {
		top: 110%;
		left: 98%;
	}
}

.confetti-58 {
	width: 15px;
	height: 6px;
	background-color: #ffbf00;
	top: -10%;
	left: 95%;
	opacity: 1.41;
	transform: rotate(299.42813deg);
	animation: drop-58 2.05089s 0.22185s;
	will-change: left, top;
}

@keyframes drop-58 {
	100% {
		top: 110%;
		left: 109%;
	}
}

.confetti-59 {
	width: 6px;
	height: 2.4px;
	background-color: #d13447;
	top: -10%;
	left: 24%;
	opacity: 0.90712;
	transform: rotate(334.10439deg);
	animation: drop-59 2.94826s 0.11386s;
	will-change: left, top;
}

@keyframes drop-59 {
	100% {
		top: 110%;
		left: 25%;
	}
}

.confetti-60 {
	width: 14px;
	height: 5.6px;
	background-color: #ffbf00;
	top: -10%;
	left: 82%;
	opacity: 0.72548;
	transform: rotate(168.14669deg);
	animation: drop-60 2.22837s 0.37624s;
	will-change: left, top;
}

@keyframes drop-60 {
	100% {
		top: 110%;
		left: 87%;
	}
}

.confetti-61 {
	width: 13px;
	height: 5.2px;
	background-color: #ffbf00;
	top: -10%;
	left: 93%;
	opacity: 1.49616;
	transform: rotate(249.36414deg);
	animation: drop-61 2.59156s 0.37884s;
	will-change: left, top;
}

@keyframes drop-61 {
	100% {
		top: 110%;
		left: 96%;
	}
}

.confetti-62 {
	width: 15px;
	height: 6px;
	background-color: #263672;
	top: -10%;
	left: 31%;
	opacity: 1.25004;
	transform: rotate(130.97732deg);
	animation: drop-62 2.68211s 0.11941s;
	will-change: left, top;
}

@keyframes drop-62 {
	100% {
		top: 110%;
		left: 34%;
	}
}

.confetti-63 {
	width: 2px;
	height: 0.8px;
	background-color: #d13447;
	top: -10%;
	left: 41%;
	opacity: 0.61113;
	transform: rotate(45.15083deg);
	animation: drop-63 2.63139s 0.97212s;
	will-change: left, top;
}

@keyframes drop-63 {
	100% {
		top: 110%;
		left: 56%;
	}
}

.confetti-64 {
	width: 1px;
	height: 0.4px;
	background-color: #263672;
	top: -10%;
	left: 10%;
	opacity: 0.89142;
	transform: rotate(352.96424deg);
	animation: drop-64 2.39124s 0.94667s;
	will-change: left, top;
}

@keyframes drop-64 {
	100% {
		top: 110%;
		left: 13%;
	}
}

.confetti-65 {
	width: 15px;
	height: 6px;
	background-color: #263672;
	top: -10%;
	left: 23%;
	opacity: 1.45072;
	transform: rotate(97.91042deg);
	animation: drop-65 2.16819s 0.87476s;
	will-change: left, top;
}

@keyframes drop-65 {
	100% {
		top: 110%;
		left: 35%;
	}
}

.confetti-66 {
	width: 15px;
	height: 6px;
	background-color: #ffbf00;
	top: -10%;
	left: 69%;
	opacity: 0.59054;
	transform: rotate(352.1475deg);
	animation: drop-66 2.49503s 0.27033s;
	will-change: left, top;
}

@keyframes drop-66 {
	100% {
		top: 110%;
		left: 72%;
	}
}

.confetti-67 {
	width: 6px;
	height: 2.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 15%;
	opacity: 0.76344;
	transform: rotate(343.73483deg);
	animation: drop-67 2.72113s 0.6856s;
	will-change: left, top;
}

@keyframes drop-67 {
	100% {
		top: 110%;
		left: 16%;
	}
}

.confetti-68 {
	width: 3px;
	height: 1.2px;
	background-color: #263672;
	top: -10%;
	left: 46%;
	opacity: 1.31779;
	transform: rotate(1.42487deg);
	animation: drop-68 2.40802s 0.80685s;
	will-change: left, top;
}

@keyframes drop-68 {
	100% {
		top: 110%;
		left: 49%;
	}
}

.confetti-69 {
	width: 12px;
	height: 4.8px;
	background-color: #ffbf00;
	top: -10%;
	left: 98%;
	opacity: 0.88129;
	transform: rotate(44.00552deg);
	animation: drop-69 2.30462s 0.59168s;
	will-change: left, top;
}

@keyframes drop-69 {
	100% {
		top: 110%;
		left: 111%;
	}
}

.confetti-70 {
	width: 5px;
	height: 2px;
	background-color: #d13447;
	top: -10%;
	left: 29%;
	opacity: 0.86907;
	transform: rotate(270.42638deg);
	animation: drop-70 2.66787s 0.28228s;
	will-change: left, top;
}

@keyframes drop-70 {
	100% {
		top: 110%;
		left: 33%;
	}
}

.confetti-71 {
	width: 12px;
	height: 4.8px;
	background-color: #ffbf00;
	top: -10%;
	left: 96%;
	opacity: 1.4256;
	transform: rotate(323.56943deg);
	animation: drop-71 2.83337s 0.67916s;
	will-change: left, top;
}

@keyframes drop-71 {
	100% {
		top: 110%;
		left: 109%;
	}
}

.confetti-72 {
	width: 1px;
	height: 0.4px;
	background-color: #263672;
	top: -10%;
	left: 46%;
	opacity: 0.76505;
	transform: rotate(51.87697deg);
	animation: drop-72 2.6472s 0.74039s;
	will-change: left, top;
}

@keyframes drop-72 {
	100% {
		top: 110%;
		left: 50%;
	}
}

.confetti-73 {
	width: 10px;
	height: 4px;
	background-color: #d13447;
	top: -10%;
	left: 28%;
	opacity: 1.37523;
	transform: rotate(92.94045deg);
	animation: drop-73 2.1274s 0.76909s;
	will-change: left, top;
}

@keyframes drop-73 {
	100% {
		top: 110%;
		left: 33%;
	}
}

.confetti-74 {
	width: 11px;
	height: 4.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 85%;
	opacity: 0.64128;
	transform: rotate(325.54861deg);
	animation: drop-74 2.31356s 0.76581s;
	will-change: left, top;
}

@keyframes drop-74 {
	100% {
		top: 110%;
		left: 95%;
	}
}

.confetti-75 {
	width: 6px;
	height: 2.4px;
	background-color: #263672;
	top: -10%;
	left: 47%;
	opacity: 1.23878;
	transform: rotate(194.57529deg);
	animation: drop-75 2.36157s 0.17802s;
	will-change: left, top;
}

@keyframes drop-75 {
	100% {
		top: 110%;
		left: 57%;
	}
}

.confetti-76 {
	width: 2px;
	height: 0.8px;
	background-color: #d13447;
	top: -10%;
	left: 65%;
	opacity: 1.49323;
	transform: rotate(46.82231deg);
	animation: drop-76 2.39494s 0.74547s;
	will-change: left, top;
}

@keyframes drop-76 {
	100% {
		top: 110%;
		left: 79%;
	}
}

.confetti-77 {
	width: 10px;
	height: 4px;
	background-color: #ffbf00;
	top: -10%;
	left: 81%;
	opacity: 1.04036;
	transform: rotate(350.84302deg);
	animation: drop-77 2.45959s 0.05279s;
	will-change: left, top;
}

@keyframes drop-77 {
	100% {
		top: 110%;
		left: 96%;
	}
}

.confetti-78 {
	width: 13px;
	height: 5.2px;
	background-color: #263672;
	top: -10%;
	left: 14%;
	opacity: 1.45751;
	transform: rotate(88.62732deg);
	animation: drop-78 2.08307s 0.5431s;
	will-change: left, top;
}

@keyframes drop-78 {
	100% {
		top: 110%;
		left: 25%;
	}
}

.confetti-79 {
	width: 13px;
	height: 5.2px;
	background-color: #ffbf00;
	top: -10%;
	left: 58%;
	opacity: 1.19844;
	transform: rotate(63.23084deg);
	animation: drop-79 2.14572s 0.14259s;
	will-change: left, top;
}

@keyframes drop-79 {
	100% {
		top: 110%;
		left: 67%;
	}
}

.confetti-80 {
	width: 3px;
	height: 1.2px;
	background-color: #263672;
	top: -10%;
	left: 79%;
	opacity: 1.24788;
	transform: rotate(254.19335deg);
	animation: drop-80 2.3737s 0.23993s;
	will-change: left, top;
}

@keyframes drop-80 {
	100% {
		top: 110%;
		left: 90%;
	}
}

.confetti-81 {
	width: 4px;
	height: 1.6px;
	background-color: #d13447;
	top: -10%;
	left: 81%;
	opacity: 1.44826;
	transform: rotate(336.19067deg);
	animation: drop-81 2.0373s 0.94971s;
	will-change: left, top;
}

@keyframes drop-81 {
	100% {
		top: 110%;
		left: 86%;
	}
}

.confetti-82 {
	width: 3px;
	height: 1.2px;
	background-color: #d13447;
	top: -10%;
	left: 49%;
	opacity: 1.36166;
	transform: rotate(55.31124deg);
	animation: drop-82 2.93911s 0.17357s;
	will-change: left, top;
}

@keyframes drop-82 {
	100% {
		top: 110%;
		left: 53%;
	}
}

.confetti-83 {
	width: 14px;
	height: 5.6px;
	background-color: #d13447;
	top: -10%;
	left: 41%;
	opacity: 1.02634;
	transform: rotate(354.98811deg);
	animation: drop-83 2.30744s 0.71916s;
	will-change: left, top;
}

@keyframes drop-83 {
	100% {
		top: 110%;
		left: 46%;
	}
}

.confetti-84 {
	width: 14px;
	height: 5.6px;
	background-color: #ffbf00;
	top: -10%;
	left: 62%;
	opacity: 0.98526;
	transform: rotate(303.47691deg);
	animation: drop-84 2.75549s 0.96613s;
	will-change: left, top;
}

@keyframes drop-84 {
	100% {
		top: 110%;
		left: 71%;
	}
}

.confetti-85 {
	width: 6px;
	height: 2.4px;
	background-color: #d13447;
	top: -10%;
	left: 3%;
	opacity: 0.5457;
	transform: rotate(201.06212deg);
	animation: drop-85 2.99416s 0.23124s;
	will-change: left, top;
}

@keyframes drop-85 {
	100% {
		top: 110%;
		left: 18%;
	}
}

.confetti-86 {
	width: 6px;
	height: 2.4px;
	background-color: #263672;
	top: -10%;
	left: 66%;
	opacity: 0.69348;
	transform: rotate(199.01859deg);
	animation: drop-86 2.97459s 0.12794s;
	will-change: left, top;
}

@keyframes drop-86 {
	100% {
		top: 110%;
		left: 78%;
	}
}

.confetti-87 {
	width: 2px;
	height: 0.8px;
	background-color: #ffbf00;
	top: -10%;
	left: 72%;
	opacity: 1.29772;
	transform: rotate(1.26738deg);
	animation: drop-87 2.95739s 0.25426s;
	will-change: left, top;
}

@keyframes drop-87 {
	100% {
		top: 110%;
		left: 85%;
	}
}

.confetti-88 {
	width: 10px;
	height: 4px;
	background-color: #d13447;
	top: -10%;
	left: 88%;
	opacity: 0.70104;
	transform: rotate(82.55518deg);
	animation: drop-88 2.34407s 0.15703s;
	will-change: left, top;
}

@keyframes drop-88 {
	100% {
		top: 110%;
		left: 94%;
	}
}

.confetti-89 {
	width: 8px;
	height: 3.2px;
	background-color: #d13447;
	top: -10%;
	left: 42%;
	opacity: 0.85888;
	transform: rotate(357.03182deg);
	animation: drop-89 2.71262s 0.5362s;
	will-change: left, top;
}

@keyframes drop-89 {
	100% {
		top: 110%;
		left: 44%;
	}
}

.confetti-90 {
	width: 8px;
	height: 3.2px;
	background-color: #263672;
	top: -10%;
	left: 39%;
	opacity: 0.99422;
	transform: rotate(22.64415deg);
	animation: drop-90 2.87015s 0.58272s;
	will-change: left, top;
}

@keyframes drop-90 {
	100% {
		top: 110%;
		left: 53%;
	}
}

.confetti-91 {
	width: 5px;
	height: 2px;
	background-color: #d13447;
	top: -10%;
	left: 41%;
	opacity: 0.77803;
	transform: rotate(226.3967deg);
	animation: drop-91 2.07495s 0.27016s;
	will-change: left, top;
}

@keyframes drop-91 {
	100% {
		top: 110%;
		left: 47%;
	}
}

.confetti-92 {
	width: 11px;
	height: 4.4px;
	background-color: #d13447;
	top: -10%;
	left: 8%;
	opacity: 1.07247;
	transform: rotate(85.20532deg);
	animation: drop-92 2.70404s 0.66568s;
	will-change: left, top;
}

@keyframes drop-92 {
	100% {
		top: 110%;
		left: 18%;
	}
}

.confetti-93 {
	width: 8px;
	height: 3.2px;
	background-color: #263672;
	top: -10%;
	left: 68%;
	opacity: 1.36532;
	transform: rotate(340.6982deg);
	animation: drop-93 2.96633s 0.71504s;
	will-change: left, top;
}

@keyframes drop-93 {
	100% {
		top: 110%;
		left: 83%;
	}
}

.confetti-94 {
	width: 2px;
	height: 0.8px;
	background-color: #ffbf00;
	top: -10%;
	left: 92%;
	opacity: 0.71297;
	transform: rotate(96.75361deg);
	animation: drop-94 2.28869s 0.25852s;
	will-change: left, top;
}

@keyframes drop-94 {
	100% {
		top: 110%;
		left: 93%;
	}
}

.confetti-95 {
	width: 6px;
	height: 2.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 88%;
	opacity: 0.71741;
	transform: rotate(102.0664deg);
	animation: drop-95 2.5456s 0.86087s;
	will-change: left, top;
}

@keyframes drop-95 {
	100% {
		top: 110%;
		left: 93%;
	}
}

.confetti-96 {
	width: 8px;
	height: 3.2px;
	background-color: #d13447;
	top: -10%;
	left: 82%;
	opacity: 1.03966;
	transform: rotate(58.67137deg);
	animation: drop-96 2.49402s 0.28477s;
	will-change: left, top;
}

@keyframes drop-96 {
	100% {
		top: 110%;
		left: 91%;
	}
}

.confetti-97 {
	width: 1px;
	height: 0.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 58%;
	opacity: 0.63572;
	transform: rotate(254.46238deg);
	animation: drop-97 2.02333s 0.72309s;
	will-change: left, top;
}

@keyframes drop-97 {
	100% {
		top: 110%;
		left: 68%;
	}
}

.confetti-98 {
	width: 10px;
	height: 4px;
	background-color: #ffbf00;
	top: -10%;
	left: 7%;
	opacity: 1.08555;
	transform: rotate(130.68071deg);
	animation: drop-98 2.45443s 0.22916s;
	will-change: left, top;
}

@keyframes drop-98 {
	100% {
		top: 110%;
		left: 22%;
	}
}

.confetti-99 {
	width: 11px;
	height: 4.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 13%;
	opacity: 1.3949;
	transform: rotate(186.2228deg);
	animation: drop-99 2.58576s 0.62759s;
	will-change: left, top;
}

@keyframes drop-99 {
	100% {
		top: 110%;
		left: 26%;
	}
}

.confetti-100 {
	width: 9px;
	height: 3.6px;
	background-color: #263672;
	top: -10%;
	left: 86%;
	opacity: 0.99186;
	transform: rotate(48.97579deg);
	animation: drop-100 2.78052s 0.00367s;
	will-change: left, top;
}

@keyframes drop-100 {
	100% {
		top: 110%;
		left: 100%;
	}
}

.confetti-101 {
	width: 9px;
	height: 3.6px;
	background-color: #ffbf00;
	top: -10%;
	left: 16%;
	opacity: 0.85277;
	transform: rotate(333.49365deg);
	animation: drop-101 2.37597s 0.65902s;
	will-change: left, top;
}

@keyframes drop-101 {
	100% {
		top: 110%;
		left: 24%;
	}
}

.confetti-102 {
	width: 5px;
	height: 2px;
	background-color: #263672;
	top: -10%;
	left: 12%;
	opacity: 0.96108;
	transform: rotate(226.58736deg);
	animation: drop-102 2.574s 0.10993s;
	will-change: left, top;
}

@keyframes drop-102 {
	100% {
		top: 110%;
		left: 25%;
	}
}

.confetti-103 {
	width: 1px;
	height: 0.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 75%;
	opacity: 1.34473;
	transform: rotate(271.42509deg);
	animation: drop-103 2.66002s 0.98116s;
	will-change: left, top;
}

@keyframes drop-103 {
	100% {
		top: 110%;
		left: 87%;
	}
}

.confetti-104 {
	width: 11px;
	height: 4.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 84%;
	opacity: 1.05897;
	transform: rotate(42.57033deg);
	animation: drop-104 2.03896s 0.18317s;
	will-change: left, top;
}

@keyframes drop-104 {
	100% {
		top: 110%;
		left: 94%;
	}
}

.confetti-105 {
	width: 15px;
	height: 6px;
	background-color: #263672;
	top: -10%;
	left: 21%;
	opacity: 0.99531;
	transform: rotate(132.00407deg);
	animation: drop-105 2.84009s 0.82829s;
	will-change: left, top;
}

@keyframes drop-105 {
	100% {
		top: 110%;
		left: 32%;
	}
}

.confetti-106 {
	width: 10px;
	height: 4px;
	background-color: #ffbf00;
	top: -10%;
	left: 26%;
	opacity: 0.67121;
	transform: rotate(287.83965deg);
	animation: drop-106 2.02898s 0.15391s;
	will-change: left, top;
}

@keyframes drop-106 {
	100% {
		top: 110%;
		left: 30%;
	}
}

.confetti-107 {
	width: 12px;
	height: 4.8px;
	background-color: #d13447;
	top: -10%;
	left: 38%;
	opacity: 0.56209;
	transform: rotate(108.0336deg);
	animation: drop-107 2.88143s 0.40974s;
	will-change: left, top;
}

@keyframes drop-107 {
	100% {
		top: 110%;
		left: 50%;
	}
}

.confetti-108 {
	width: 8px;
	height: 3.2px;
	background-color: #263672;
	top: -10%;
	left: 89%;
	opacity: 0.85767;
	transform: rotate(95.96384deg);
	animation: drop-108 2.90966s 0.85441s;
	will-change: left, top;
}

@keyframes drop-108 {
	100% {
		top: 110%;
		left: 93%;
	}
}

.confetti-109 {
	width: 11px;
	height: 4.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 26%;
	opacity: 1.14771;
	transform: rotate(194.97724deg);
	animation: drop-109 2.53353s 0.33403s;
	will-change: left, top;
}

@keyframes drop-109 {
	100% {
		top: 110%;
		left: 27%;
	}
}

.confetti-110 {
	width: 15px;
	height: 6px;
	background-color: #ffbf00;
	top: -10%;
	left: 16%;
	opacity: 1.32921;
	transform: rotate(146.08086deg);
	animation: drop-110 2.44687s 0.26858s;
	will-change: left, top;
}

@keyframes drop-110 {
	100% {
		top: 110%;
		left: 20%;
	}
}

.confetti-111 {
	width: 13px;
	height: 5.2px;
	background-color: #ffbf00;
	top: -10%;
	left: 75%;
	opacity: 0.61484;
	transform: rotate(0.44411deg);
	animation: drop-111 2.02417s 0.56887s;
	will-change: left, top;
}

@keyframes drop-111 {
	100% {
		top: 110%;
		left: 90%;
	}
}

.confetti-112 {
	width: 15px;
	height: 6px;
	background-color: #ffbf00;
	top: -10%;
	left: 96%;
	opacity: 0.99835;
	transform: rotate(18.64539deg);
	animation: drop-112 2.99091s 0.64993s;
	will-change: left, top;
}

@keyframes drop-112 {
	100% {
		top: 110%;
		left: 109%;
	}
}

.confetti-113 {
	width: 2px;
	height: 0.8px;
	background-color: #ffbf00;
	top: -10%;
	left: 70%;
	opacity: 1.18467;
	transform: rotate(339.77888deg);
	animation: drop-113 2.62237s 0.65932s;
	will-change: left, top;
}

@keyframes drop-113 {
	100% {
		top: 110%;
		left: 76%;
	}
}

.confetti-114 {
	width: 9px;
	height: 3.6px;
	background-color: #d13447;
	top: -10%;
	left: 92%;
	opacity: 0.66928;
	transform: rotate(9.62456deg);
	animation: drop-114 2.73155s 0.20861s;
	will-change: left, top;
}

@keyframes drop-114 {
	100% {
		top: 110%;
		left: 94%;
	}
}

.confetti-115 {
	width: 6px;
	height: 2.4px;
	background-color: #263672;
	top: -10%;
	left: 46%;
	opacity: 1.48324;
	transform: rotate(357.2533deg);
	animation: drop-115 2.16384s 0.06624s;
	will-change: left, top;
}

@keyframes drop-115 {
	100% {
		top: 110%;
		left: 59%;
	}
}

.confetti-116 {
	width: 14px;
	height: 5.6px;
	background-color: #263672;
	top: -10%;
	left: 28%;
	opacity: 1.24143;
	transform: rotate(188.65674deg);
	animation: drop-116 2.31342s 0.44765s;
	will-change: left, top;
}

@keyframes drop-116 {
	100% {
		top: 110%;
		left: 34%;
	}
}

.confetti-117 {
	width: 8px;
	height: 3.2px;
	background-color: #d13447;
	top: -10%;
	left: 53%;
	opacity: 0.93686;
	transform: rotate(82.47522deg);
	animation: drop-117 2.74115s 0.8024s;
	will-change: left, top;
}

@keyframes drop-117 {
	100% {
		top: 110%;
		left: 68%;
	}
}

.confetti-118 {
	width: 5px;
	height: 2px;
	background-color: #ffbf00;
	top: -10%;
	left: 75%;
	opacity: 1.38591;
	transform: rotate(5.14296deg);
	animation: drop-118 2.60179s 0.92679s;
	will-change: left, top;
}

@keyframes drop-118 {
	100% {
		top: 110%;
		left: 86%;
	}
}

.confetti-119 {
	width: 3px;
	height: 1.2px;
	background-color: #d13447;
	top: -10%;
	left: 8%;
	opacity: 0.50216;
	transform: rotate(28.5481deg);
	animation: drop-119 2.2832s 0.42516s;
	will-change: left, top;
}

@keyframes drop-119 {
	100% {
		top: 110%;
		left: 17%;
	}
}

.confetti-120 {
	width: 13px;
	height: 5.2px;
	background-color: #ffbf00;
	top: -10%;
	left: 93%;
	opacity: 1.03433;
	transform: rotate(200.78181deg);
	animation: drop-120 2.54113s 0.94346s;
	will-change: left, top;
}

@keyframes drop-120 {
	100% {
		top: 110%;
		left: 104%;
	}
}

.confetti-121 {
	width: 9px;
	height: 3.6px;
	background-color: #d13447;
	top: -10%;
	left: 90%;
	opacity: 1.48056;
	transform: rotate(55.33135deg);
	animation: drop-121 2.35789s 0.8379s;
	will-change: left, top;
}

@keyframes drop-121 {
	100% {
		top: 110%;
		left: 102%;
	}
}

.confetti-122 {
	width: 15px;
	height: 6px;
	background-color: #ffbf00;
	top: -10%;
	left: 86%;
	opacity: 0.77811;
	transform: rotate(341.66237deg);
	animation: drop-122 2.29696s 0.23147s;
	will-change: left, top;
}

@keyframes drop-122 {
	100% {
		top: 110%;
		left: 98%;
	}
}

.confetti-123 {
	width: 10px;
	height: 4px;
	background-color: #d13447;
	top: -10%;
	left: 70%;
	opacity: 0.54796;
	transform: rotate(60.32451deg);
	animation: drop-123 2.9481s 0.93987s;
	will-change: left, top;
}

@keyframes drop-123 {
	100% {
		top: 110%;
		left: 77%;
	}
}

.confetti-124 {
	width: 14px;
	height: 5.6px;
	background-color: #ffbf00;
	top: -10%;
	left: 21%;
	opacity: 0.52671;
	transform: rotate(189.31773deg);
	animation: drop-124 2.40612s 0.54824s;
	will-change: left, top;
}

@keyframes drop-124 {
	100% {
		top: 110%;
		left: 26%;
	}
}

.confetti-125 {
	width: 14px;
	height: 5.6px;
	background-color: #d13447;
	top: -10%;
	left: 74%;
	opacity: 0.82619;
	transform: rotate(300.18742deg);
	animation: drop-125 2.28219s 0.19815s;
	will-change: left, top;
}

@keyframes drop-125 {
	100% {
		top: 110%;
		left: 82%;
	}
}

.confetti-126 {
	width: 4px;
	height: 1.6px;
	background-color: #d13447;
	top: -10%;
	left: 47%;
	opacity: 0.8338;
	transform: rotate(216.41096deg);
	animation: drop-126 2.13259s 0.67872s;
	will-change: left, top;
}

@keyframes drop-126 {
	100% {
		top: 110%;
		left: 58%;
	}
}

.confetti-127 {
	width: 1px;
	height: 0.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 98%;
	opacity: 0.52344;
	transform: rotate(263.28555deg);
	animation: drop-127 2.14292s 0.32255s;
	will-change: left, top;
}

@keyframes drop-127 {
	100% {
		top: 110%;
		left: 107%;
	}
}

.confetti-128 {
	width: 15px;
	height: 6px;
	background-color: #263672;
	top: -10%;
	left: 81%;
	opacity: 1.46657;
	transform: rotate(137.59249deg);
	animation: drop-128 2.25839s 0.07485s;
	will-change: left, top;
}

@keyframes drop-128 {
	100% {
		top: 110%;
		left: 87%;
	}
}

.confetti-129 {
	width: 3px;
	height: 1.2px;
	background-color: #ffbf00;
	top: -10%;
	left: 52%;
	opacity: 1.132;
	transform: rotate(156.22372deg);
	animation: drop-129 2.32527s 0.06732s;
	will-change: left, top;
}

@keyframes drop-129 {
	100% {
		top: 110%;
		left: 57%;
	}
}

.confetti-130 {
	width: 2px;
	height: 0.8px;
	background-color: #d13447;
	top: -10%;
	left: 30%;
	opacity: 1.41495;
	transform: rotate(31.49732deg);
	animation: drop-130 2.25037s 0.46631s;
	will-change: left, top;
}

@keyframes drop-130 {
	100% {
		top: 110%;
		left: 33%;
	}
}

.confetti-131 {
	width: 2px;
	height: 0.8px;
	background-color: #263672;
	top: -10%;
	left: 5%;
	opacity: 1.49349;
	transform: rotate(308.17816deg);
	animation: drop-131 2.12485s 0.91244s;
	will-change: left, top;
}

@keyframes drop-131 {
	100% {
		top: 110%;
		left: 18%;
	}
}

.confetti-132 {
	width: 4px;
	height: 1.6px;
	background-color: #d13447;
	top: -10%;
	left: 58%;
	opacity: 0.77996;
	transform: rotate(273.53445deg);
	animation: drop-132 2.43791s 0.51556s;
	will-change: left, top;
}

@keyframes drop-132 {
	100% {
		top: 110%;
		left: 64%;
	}
}

.confetti-133 {
	width: 11px;
	height: 4.4px;
	background-color: #d13447;
	top: -10%;
	left: 97%;
	opacity: 0.80372;
	transform: rotate(87.88682deg);
	animation: drop-133 2.62199s 0.67701s;
	will-change: left, top;
}

@keyframes drop-133 {
	100% {
		top: 110%;
		left: 99%;
	}
}

.confetti-134 {
	width: 9px;
	height: 3.6px;
	background-color: #ffbf00;
	top: -10%;
	left: 84%;
	opacity: 0.61334;
	transform: rotate(147.5433deg);
	animation: drop-134 2.17344s 0.21881s;
	will-change: left, top;
}

@keyframes drop-134 {
	100% {
		top: 110%;
		left: 90%;
	}
}

.confetti-135 {
	width: 9px;
	height: 3.6px;
	background-color: #d13447;
	top: -10%;
	left: 5%;
	opacity: 1.17203;
	transform: rotate(64.42622deg);
	animation: drop-135 2.11502s 0.94529s;
	will-change: left, top;
}

@keyframes drop-135 {
	100% {
		top: 110%;
		left: 13%;
	}
}

.confetti-136 {
	width: 6px;
	height: 2.4px;
	background-color: #263672;
	top: -10%;
	left: 73%;
	opacity: 1.14891;
	transform: rotate(193.42507deg);
	animation: drop-136 2.77693s 0.36525s;
	will-change: left, top;
}

@keyframes drop-136 {
	100% {
		top: 110%;
		left: 78%;
	}
}

.confetti-137 {
	width: 12px;
	height: 4.8px;
	background-color: #d13447;
	top: -10%;
	left: 3%;
	opacity: 0.94065;
	transform: rotate(346.07192deg);
	animation: drop-137 2.67967s 0.95404s;
	will-change: left, top;
}

@keyframes drop-137 {
	100% {
		top: 110%;
		left: 14%;
	}
}

.confetti-138 {
	width: 8px;
	height: 3.2px;
	background-color: #263672;
	top: -10%;
	left: 89%;
	opacity: 1.18847;
	transform: rotate(3.58455deg);
	animation: drop-138 2.62512s 0.89275s;
	will-change: left, top;
}

@keyframes drop-138 {
	100% {
		top: 110%;
		left: 93%;
	}
}

.confetti-139 {
	width: 11px;
	height: 4.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 11%;
	opacity: 0.64705;
	transform: rotate(78.64582deg);
	animation: drop-139 2.94893s 0.67084s;
	will-change: left, top;
}

@keyframes drop-139 {
	100% {
		top: 110%;
		left: 20%;
	}
}

.confetti-140 {
	width: 4px;
	height: 1.6px;
	background-color: #d13447;
	top: -10%;
	left: 23%;
	opacity: 0.70014;
	transform: rotate(243.67865deg);
	animation: drop-140 2.07925s 0.16957s;
	will-change: left, top;
}

@keyframes drop-140 {
	100% {
		top: 110%;
		left: 26%;
	}
}

.confetti-141 {
	width: 3px;
	height: 1.2px;
	background-color: #d13447;
	top: -10%;
	left: 7%;
	opacity: 1.03279;
	transform: rotate(86.46521deg);
	animation: drop-141 2.66241s 0.49444s;
	will-change: left, top;
}

@keyframes drop-141 {
	100% {
		top: 110%;
		left: 13%;
	}
}

.confetti-142 {
	width: 2px;
	height: 0.8px;
	background-color: #d13447;
	top: -10%;
	left: 82%;
	opacity: 1.28125;
	transform: rotate(8.71276deg);
	animation: drop-142 2.42858s 0.38697s;
	will-change: left, top;
}

@keyframes drop-142 {
	100% {
		top: 110%;
		left: 92%;
	}
}

.confetti-143 {
	width: 1px;
	height: 0.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 86%;
	opacity: 1.15172;
	transform: rotate(258.89404deg);
	animation: drop-143 2.64708s 0.21912s;
	will-change: left, top;
}

@keyframes drop-143 {
	100% {
		top: 110%;
		left: 90%;
	}
}

.confetti-144 {
	width: 6px;
	height: 2.4px;
	background-color: #ffbf00;
	top: -10%;
	left: 50%;
	opacity: 1.11186;
	transform: rotate(307.90988deg);
	animation: drop-144 2.01613s 0.6689s;
	will-change: left, top;
}

@keyframes drop-144 {
	100% {
		top: 110%;
		left: 64%;
	}
}

.confetti-145 {
	width: 2px;
	height: 0.8px;
	background-color: #263672;
	top: -10%;
	left: 12%;
	opacity: 0.72101;
	transform: rotate(257.53364deg);
	animation: drop-145 2.27441s 0.60437s;
	will-change: left, top;
}

@keyframes drop-145 {
	100% {
		top: 110%;
		left: 22%;
	}
}

.confetti-146 {
	width: 11px;
	height: 4.4px;
	background-color: #263672;
	top: -10%;
	left: 31%;
	opacity: 0.96606;
	transform: rotate(183.63087deg);
	animation: drop-146 2.57761s 0.32515s;
	will-change: left, top;
}

@keyframes drop-146 {
	100% {
		top: 110%;
		left: 37%;
	}
}

.confetti-147 {
	width: 5px;
	height: 2px;
	background-color: #263672;
	top: -10%;
	left: 45%;
	opacity: 1.29929;
	transform: rotate(239.89351deg);
	animation: drop-147 2.9793s 0.36125s;
	will-change: left, top;
}

@keyframes drop-147 {
	100% {
		top: 110%;
		left: 51%;
	}
}

.confetti-148 {
	width: 8px;
	height: 3.2px;
	background-color: #263672;
	top: -10%;
	left: 100%;
	opacity: 1.09974;
	transform: rotate(192.78829deg);
	animation: drop-148 2.12251s 0.40252s;
	will-change: left, top;
}

@keyframes drop-148 {
	100% {
		top: 110%;
		left: 110%;
	}
}

.confetti-149 {
	width: 15px;
	height: 6px;
	background-color: #263672;
	top: -10%;
	left: 7%;
	opacity: 1.3478;
	transform: rotate(234.74725deg);
	animation: drop-149 2.56943s 0.59962s;
	will-change: left, top;
}

@keyframes drop-149 {
	100% {
		top: 110%;
		left: 20%;
	}
}

.confetti-150 {
	width: 10px;
	height: 4px;
	background-color: #ffbf00;
	top: -10%;
	left: 63%;
	opacity: 0.53755;
	transform: rotate(164.0579deg);
	animation: drop-150 2.4364s 0.74598s;
	will-change: left, top;
}

@keyframes drop-150 {
	100% {
		top: 110%;
		left: 77%;
	}
}
