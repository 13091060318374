h1,
h2,
h3,
h4,
h5,
h6 {
    &.ant-typography {
        color: #252525;
        margin-top: 0;
        font-weight: 500;
    }
}
