/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    background: transparent !important;
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}


// Labels
.ant-form {
    label {
        color: rgba(black, 0.75);
    }

    .ant-form-item {
        max-width: 100%;
        margin-bottom: 10px;
    }

    .ant-form-item-extra {
        margin-top: 0.2rem;
    }
    &.ant-form-inline .ant-form-item {
        margin-right: 10px;
    }
}

.hidden-label {
    .ant-form-item-label {
        opacity: 0; // ATTN! Just testing...
    }
}

.ant-form-item-label > label::after {
    content: "";
}

// Textarea
.ant-form-item-control {
    textarea {
        // font-size: 1rem;
    }
}

.ant-form-item-required {
    &:before {
        font-size: 1.4rem !important;
        line-height: 0;
        padding: 0;
        position: relative;
        top: 0.3rem;
        font-weight: @weight-bold;
    }
}

.ant-form-item-explain {
    line-height: 1.2;
    margin: 5px 0 -10px;
    .hide-all-hints & {
        display: none !important;
    }
}

// Select
.ant-select {
    width: 100%;
}

.ant-select-selection {
    &.ant-select-selection--multiple {
        border-color: #e8e8e8;
        box-shadow: inset 1px 1px 2px rgba(black, 0.05);
        padding: 0;
        margin: 0;
        min-height: unset;
        max-height: unset;

        ul {
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;

            li {
                max-width: 140px;
            }
        }
    }
}

.ant-select-loading {
    .ant-select-selection-selected-value {
        opacity: 0 !important; // hide placeholder value until data loaded
    }
}

// borders
.ant-input,
.ant-picker,
.ant-select .ant-select-selector,
.ant-input-search-icon::before,
.ant-input-affix-wrapper,
.ant-input-search-button,
.ant-radio-button-wrapper,
.ant-radio-button-wrapper:first-child,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: @border-color;
}

.ant-input-affix-wrapper {
    &.focused {
        box-shadow: 0 0 0 1px @border-color !important;
    }
}

.ant-radio-group {
    border-radius: 4px;
    display: flex;
    gap: 0 0.4rem;
    label.ant-radio-button-wrapper {
        border-radius: 4px;
        overflow: hidden;
        border: 1px solid @border-color;
    }
    .ant-radio-button-wrapper-checked {
        &:not(.ant-radio-button-wrapper-disabled) {
            box-shadow: 0 2px 5px 0 rgba(@primary, 0.3) !important;
            transform: scale(1);

            i {
                color: #fff;
                opacity: 1;
                font-weight: @weight-bold;
            }
        }
    }
}

// Disabled
.ant-select-disabled {
    .ant-select-selection {
        background: #fafafa; // more soft background
    }
}

// Fix
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 0;
}

.ant-input-number,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:hover,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: @border-color;
    box-shadow: none;
}

.ant-select-multiple .ant-select-selection-item {
    border-radius: 5px;
    border-color: @border-color;
    background: #fff;
}

.ant-input-search-button {
    box-shadow: none !important;
}

// Disable red ugly outline
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:focus,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:focus,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input-focused,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused {
    box-shadow: none;
}
