.ant-badge {
    will-change: transform;
    z-index: 1;
    .ant-scroll-number-only > p.ant-scroll-number-only-unit {
        font-family: sans-serif;
        font-weight: @weight-bold;
        font-size: 0.8rem;
    }

    .ant-badge-count {
        box-shadow: 0 2px 3px rgba(@danger, 0.5);
        font-family: sans-serif;
        font-weight: 600;
    }

    &.badge-percent {
        .ant-badge-count {
            box-shadow: none;
            background: transparent;
            color: #252525;
            padding-right: 1rem;
            border-radius: 0;
            line-height: 1;
            font-family: @font-base;
            text-align: left;
            padding: 0 0.9rem 0 0;

            &:after {
                position: absolute;
                content: "%";
                font-size: 0.9rem;
                top: 1px;
                right: 0;
            }

            p {
                font-size: 1rem;
                font-weight: 500;
                color: @primary;
            }
        }
    }

    &.badge-primary {
        .ant-badge-count {
            // animation: none; // disabled
            background: @primary;
            box-shadow: 0 2px 4px rgba(@primary, 0.3) !important;
            font-weight: @weight-bold;
            color: #fff;

            p {
                font-size: 0.75rem;
                color: rgba(#fff, 0.9);
            }
        }
    }

    &.small {
        transform: scale(0.8);

        sup.ant-badge-count {
            font-weight: bold !important;
            font-size: 0.1rem !important;
        }
    }
}
