// Override variables
@import "../../styles/core/_app-variables";
@import "../../styles/core/_mixins";
div.SpinFullscreen,
:global(.ant-spin.ant-spin-lg) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 102;
    display: flex;
    align-items: center;
    justify-content: center;

    :global {
        .ant-spin-dot {
            animation-timing-function: cubic-bezier(0.4, 0, 0.04, 1.71);
            animation-duration: 2.2s;
            .size(40px);
        }

        span.ant-spin-lg {
            .ant-spin-dot {
                font-size: 10rem;
            }
        }

        i.ant-spin-dot-item {
            .size(10px);
            :local {
                animation: dots-resize 1s ease 0.5s infinite;
            }
            opacity: 0.5;

            &:nth-child(1) {
                background: @blue;
            }

            &:nth-child(2) {
                background: @blue;
            }

            &:nth-child(3) {
                background: @blue;
            }

            &:nth-child(4) {
                background: @blue;
            }
        }

        .ant-spin-text {
            margin-top: 1rem;
            color: @primary;
            font-weight: bold;
        }
    }
}

:global {
    .dashboard.isGuest {
        :local {
            div.SpinFullscreen {
                left: calc(50%);
            }
        }
    }
    .ant-popover-inner-content {
        :local {
            div.SpinFullscreen {
                top: 45%;
                left: calc(50%);
            }
        }
    }
}

@keyframes dots-resize {
    from {
        .size(10px);
    }

    50% {
        .size(28px);
    }

    to {
        .size(10px);
    }
}
