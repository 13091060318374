// Check dashboard ready

.ant-drawer {
    .ant-drawer-header {
        border-radius: 0;

        background: linear-gradient(15deg, @app-background-one 0, @app-background-two 150%);
         border: none;

        .ant-drawer-close {
            color: #fff;
        }

        .ant-drawer-title {
            color: #fff;

            i {
                font-size: 1.1rem;
            }
        }
    }

    .ant-drawer-mask {
        animation: none;
        background: rgba(black, 0.05);
    }
}

.ant-drawer-right.ant-drawer-open {
    .ant-drawer-content-wrapper {
        box-shadow: -5px 0 10px -8px rgba(black, 0.2);
    }
}

.ant-select {
    // min-width: 80px;
    .ant-select-selector {
        border-color: #e6ecf4;
    }

    &.ant-select-show-arrow {
        .ant-select-selector {
            padding-right: 1.5rem;
        }
    }

    &.ant-select-borderless.ant-select-multiple {
        .ant-select-selector {
            padding: 0;

            .ant-select-selection-item {
                .light-shadows;
                background: #fff;
                border: none;
            }
        }
    }
}

.ant-popover {
    .ant-popover-inner {
        border-radius: 12px;
    }
}
.ant-popover-message {
    > .anticon {
        font-size: 18px;
    }
}


.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #f0f0f0;
}

.ant-tag {
    border-radius: 5px;
    user-select: none;
}

.ant-avatar {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;

    &.ant-avatar-image {
        .tiny-shadows;
    }
    .ant-avatar-string {
        text-shadow: 1px 1px 0 rgba(black, 0.1);
        font-weight: 900;
    }
}
.ant-avatar-lg {
    min-width: 40px;
}

.ant-pagination {
    li.ant-pagination-item,
    button[class] {
        border-color: @border-color;
        border-radius: 4px;
    }

    li.ant-pagination-item-active {
        border-color: #1890ff;
    }
}

//
.ant-tabs-tab-btn {
    text-shadow: none !important;
}

.ant-message {
    .ant-message-notice-content {
        border-radius: @card-border-radius;
        font-size: 17px;
        .light-shadows;

        svg {
            .size(20px);
        }
    }
}

.ant-modal-mask {
    background: rgba(black, 0.1);
}

.ant-modal-content {
    border-radius: @card-border-radius;

    .ant-modal-header {
        border-radius: @card-border-radius @card-border-radius 0 0;
    }
}

div.ant-dropdown > .ant-dropdown-menu {
    border-radius: @card-border-radius;
    border: none;
    overflow: hidden;
}

.ant-tabs-tabpane {
    padding: 0.5rem;
}

.ant-dropdown-trigger {
    cursor: pointer;
}

.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
    color: #1890ff;
    font-size: 12px;
}

.ant-alert {
    border-radius: 10px;
}

.ant-tooltip-inner {
    border-radius: 8px;
}

// attn! temporary solution...
.value {
    font-size: 1.25rem;
    font-family: sans-serif;
}

.ant-picker-ranges {
    display: flex;
    gap: 0.5rem;
}

// Hmm! Debug it...
.html {
    a {
        text-decoration: underline;
        color: @primary;
    }
    &.bf-content.bf-content {
        max-height: unset;
    }
}

.ExtLink {
    word-break: break-all;
}

.ant-comment-avatar img {
    width: 100%;
    height: 100%;
}

.ant-steps-item {
    .ant-steps-item-container {
        align-items: center;
        display: flex;
        height: 32px;
    }
    .ant-steps-item-content {
        .ant-steps-item-description {
            font-size: 10px;
        }
    }

    .ant-steps-icon {
        display: flex;
        height: 100%;
        width: 100%;
        // border: 1px solid blue;
        align-items: center;
        justify-content: center;
        min-width: 22px;
    }
    &.step-no-transition {
        .ant-steps-item-icon {
            border-radius: 50%;
            transition: none;
        }
    }
}

.SelectAnchors {
    .ant-form-item {
        .ant-col-6 + .ant-col {
            max-width: 75%;
        }
    }
}

.ant-form-item-with-help .ant-form-item-explain {
    min-height: 0 !important;
    margin: 0 !important;
    transition: none !important;

    .ant-form-item-explain-error {
        &:not(:empty) {
            padding: 5px 0;
        }
    }
}

.ant-form-item-label {
    .ant-form-item-required:before {
        top: 0 !important;
        font-size: 18px !important;
        line-height: inherit !important;
        font-weight: 400;
        font-family: inherit !important;
    }
}


.ant-space-item:empty {
    + .ant-space-item-split {
        display: none;
    }
}
