.text-right {
  text-align: right !important;
}
.small {
  font-size: 80%;
  font-weight: 400;
}
.text-left {
  text-align: left !important;
}
.text-center {
  text-align: center !important;
}
h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h1,
.h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1,
  .h1 {
    font-size: 2.5rem;
  }
}
h2,
.h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}
h3,
.h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}
h4,
.h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}
h5,
.h5 {
  font-size: 1.25rem;
}
h6,
.h6 {
  font-size: 1rem;
}
.w-25 {
  width: 25% !important;
}
.w-50 {
  width: 50% !important;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100% !important;
}
.w-auto {
  width: auto !important;
}
.h-25 {
  height: 25% !important;
}
.h-50 {
  height: 50% !important;
}
.h-75 {
  height: 75% !important;
}
.h-100 {
  height: 100% !important;
}
.h-auto {
  height: auto !important;
}
.mw-100 {
  max-width: 100% !important;
}
.mh-100 {
  max-height: 100% !important;
}
.min-vw-100 {
  min-width: 100vw !important;
}
.min-vh-100 {
  min-height: 100vh !important;
}
.vw-100 {
  width: 100vw !important;
}
.vh-100 {
  height: 100vh !important;
}
.m-0 {
  margin: 0 !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}
.mr-0,
.mx-0 {
  margin-right: 0 !important;
}
.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}
.ml-0,
.mx-0 {
  margin-left: 0 !important;
}
.m-1 {
  margin: 0.25rem !important;
}
.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}
.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}
.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}
.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}
.m-2 {
  margin: 0.5rem !important;
}
.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}
.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.m-3 {
  margin: 1rem !important;
}
.mt-3,
.my-3 {
  margin-top: 1rem !important;
}
.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}
.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
.m-4 {
  margin: 1.5rem !important;
}
.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}
.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}
.m-5 {
  margin: 3rem !important;
}
.mt-5,
.my-5 {
  margin-top: 3rem !important;
}
.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}
.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}
.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}
.p-0 {
  padding: 0 !important;
}
.pt-0,
.py-0 {
  padding-top: 0 !important;
}
.pr-0,
.px-0 {
  padding-right: 0 !important;
}
.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}
.pl-0,
.px-0 {
  padding-left: 0 !important;
}
.p-1 {
  padding: 0.25rem !important;
}
.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}
.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}
.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}
.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}
.p-2 {
  padding: 0.5rem !important;
}
.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}
.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}
.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}
.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}
.p-3 {
  padding: 1rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.pr-3,
.px-3 {
  padding-right: 1rem !important;
}
.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}
.pl-3,
.px-3 {
  padding-left: 1rem !important;
}
.p-4 {
  padding: 1.5rem !important;
}
.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}
.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}
.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}
.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}
.p-5 {
  padding: 3rem !important;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}
.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.m-n1 {
  margin: -0.25rem !important;
}
.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}
.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}
.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}
.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}
.m-n2 {
  margin: -0.5rem !important;
}
.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}
.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}
.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}
.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}
.m-n3 {
  margin: -1rem !important;
}
.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}
.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}
.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}
.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}
.m-n4 {
  margin: -1.5rem !important;
}
.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}
.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}
.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}
.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}
.m-n5 {
  margin: -3rem !important;
}
.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}
.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}
.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}
.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}
.m-auto {
  margin: auto !important;
}
.mt-auto,
.my-auto {
  margin-top: auto !important;
}
.mr-auto,
.mx-auto {
  margin-right: auto !important;
}
.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}
.ml-auto,
.mx-auto {
  margin-left: auto !important;
}
@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.ant-drawer .ant-drawer-header {
  border-radius: 0;
  background: linear-gradient(15deg, #096dd9 0, #09c6d9 150%);
  border: none;
}
.ant-drawer .ant-drawer-header .ant-drawer-close {
  color: #fff;
}
.ant-drawer .ant-drawer-header .ant-drawer-title {
  color: #fff;
}
.ant-drawer .ant-drawer-header .ant-drawer-title i {
  font-size: 1.1rem;
}
.ant-drawer .ant-drawer-mask {
  animation: none;
  background: rgba(0, 0, 0, 0.05);
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -5px 0 10px -8px rgba(0, 0, 0, 0.2);
}
.ant-select .ant-select-selector {
  border-color: #e6ecf4;
}
.ant-select.ant-select-show-arrow .ant-select-selector {
  padding-right: 1.5rem;
}
.ant-select.ant-select-borderless.ant-select-multiple .ant-select-selector {
  padding: 0;
}
.ant-select.ant-select-borderless.ant-select-multiple .ant-select-selector .ant-select-selection-item {
  box-shadow: 0 4px 14px -4px #1558a809, 0 2px 15px -5px rgba(21, 88, 168, 0.15);
  background: #fff;
  border: none;
}
.ant-popover .ant-popover-inner {
  border-radius: 12px;
}
.ant-popover-message > .anticon {
  font-size: 18px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #f0f0f0;
}
.ant-tag {
  border-radius: 5px;
  user-select: none;
}
.ant-avatar {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.ant-avatar.ant-avatar-image {
  box-shadow: 0 4px 14px -4px #1558a809, 0 2px 10px -5px rgba(21, 88, 168, 0.25);
}
.ant-avatar .ant-avatar-string {
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.1);
  font-weight: 900;
}
.ant-avatar-lg {
  min-width: 40px;
}
.ant-pagination li.ant-pagination-item,
.ant-pagination button[class] {
  border-color: #f1f3f6;
  border-radius: 4px;
}
.ant-pagination li.ant-pagination-item-active {
  border-color: #1890ff;
}
.ant-tabs-tab-btn {
  text-shadow: none !important;
}
.ant-message .ant-message-notice-content {
  border-radius: 12px;
  font-size: 17px;
  box-shadow: 0 4px 14px -4px #1558a809, 0 2px 15px -5px rgba(21, 88, 168, 0.15);
}
.ant-message .ant-message-notice-content svg {
  width: 20px;
  height: 20px;
}
.ant-modal-mask {
  background: rgba(0, 0, 0, 0.1);
}
.ant-modal-content {
  border-radius: 12px;
}
.ant-modal-content .ant-modal-header {
  border-radius: 12px 12px 0 0;
}
div.ant-dropdown > .ant-dropdown-menu {
  border-radius: 12px;
  border: none;
  overflow: hidden;
}
.ant-tabs-tabpane {
  padding: 0.5rem;
}
.ant-dropdown-trigger {
  cursor: pointer;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #1890ff;
  font-size: 12px;
}
.ant-alert {
  border-radius: 10px;
}
.ant-tooltip-inner {
  border-radius: 8px;
}
.value {
  font-size: 1.25rem;
  font-family: sans-serif;
}
.ant-picker-ranges {
  display: flex;
  gap: 0.5rem;
}
.html a {
  text-decoration: underline;
  color: #1b70ff;
}
.html.bf-content.bf-content {
  max-height: unset;
}
.ExtLink {
  word-break: break-all;
}
.ant-comment-avatar img {
  width: 100%;
  height: 100%;
}
.ant-steps-item .ant-steps-item-container {
  align-items: center;
  display: flex;
  height: 32px;
}
.ant-steps-item .ant-steps-item-content .ant-steps-item-description {
  font-size: 10px;
}
.ant-steps-item .ant-steps-icon {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-width: 22px;
}
.ant-steps-item.step-no-transition .ant-steps-item-icon {
  border-radius: 50%;
  transition: none;
}
.SelectAnchors .ant-form-item .ant-col-6 + .ant-col {
  max-width: 75%;
}
.ant-form-item-with-help .ant-form-item-explain {
  min-height: 0 !important;
  margin: 0 !important;
  transition: none !important;
}
.ant-form-item-with-help .ant-form-item-explain .ant-form-item-explain-error:not(:empty) {
  padding: 5px 0;
}
.ant-form-item-label .ant-form-item-required:before {
  top: 0 !important;
  font-size: 18px !important;
  line-height: inherit !important;
  font-weight: 400;
  font-family: inherit !important;
}
.ant-space-item:empty + .ant-space-item-split {
  display: none;
}
.ant-btn {
  border-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.ant-btn:not(.ant-btn-circle) {
  border-radius: 32px;
}
.ant-btn:hover {
  border-color: transparent;
}
.ant-btn.ant-btn-primary:disabled {
  border-color: transparent;
  background: rgba(67, 97, 255, 0.5);
  color: #fff;
}
.ant-btn i {
  margin-right: 0.3rem;
  min-width: 1ch;
}
.ant-btn.is-loading {
  user-select: none;
  pointer-events: none;
  transition: all 0s;
  opacity: 0.8;
}
.ant-btn.is-loading i {
  font-weight: 700;
}
.ant-btn.ant-btn-circle i {
  margin: 0 !important;
}
.ant-btn:not(.ant-btn-link):not(.ant-btn-text) {
  box-shadow: 0 2px 4px -1px rgba(24, 91, 126, 0.2), 0 -1px 0 rgba(24, 91, 126, 0.04);
}
.ant-btn:not([disabled]).ant-btn-primary {
  background: #1b70ff;
  box-shadow: 0 3px 8px rgba(27, 112, 255, 0.3);
}
.ant-btn:not([disabled]).ant-btn-primary:hover {
  box-shadow: 0 6px 10px rgba(27, 112, 255, 0.4);
  background: #3580ff;
}
.ant-btn:not([disabled]).ant-btn-cta {
  padding: 0.4rem 1rem;
  font-weight: 500;
  height: unset;
  color: #fff;
  background: #1b70ff;
  box-shadow: 0 2px 5px rgba(27, 112, 255, 0.4);
  border: none;
}
.ant-btn:not([disabled]).ant-btn-success {
  box-shadow: 0 2px 4px 1px rgba(11, 207, 151, 0.5);
  background: #0bcf97;
  border-color: #0bcf97;
  color: #fff;
}
.ant-btn.actions-btn {
  position: relative;
  border: none;
}
.ant-btn:not(.ant-btn-circle).ant-btn-primary.ant-btn-lg {
  padding: 0.3rem 1.4rem;
}
.ant-btn.ant-btn-square i {
  margin: 0;
}
.ant-btn.ant-btn-link-danger {
  padding: 0;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  background: none !important;
  color: #fa5c7c;
}
.ant-btn.ant-btn-link-danger::before,
.ant-btn.ant-btn-link-danger::after {
  display: none !important;
}
.ant-btn.ant-btn-link-danger > span {
  padding: 0;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  background: none !important;
}
.ant-btn.btn-circle {
  box-shadow: 0 4px 14px -4px #1558a809, 0 2px 10px -5px rgba(21, 88, 168, 0.25);
  width: 32px;
  height: 32px;
  background: #fff;
  border: none;
  font-size: 14px;
  margin-right: 0.3rem;
  padding: 0;
}
.ant-btn.btn-circle:not([disabled]).action-edit {
  color: #8fa9c0;
}
.ant-btn.btn-circle:not([disabled]).action-approve {
  color: #0bcf97;
}
.ant-btn.btn-circle:not([disabled]).action-approve:hover {
  transform: scale(1.2) rotate(-15deg) translateY(-2px);
}
.ant-form-item-control-input .ant-btn-icon-only {
  box-shadow: none;
}
.ant-notification-notice {
  max-height: 80vh;
}
.ant-notification-notice-close {
  padding-left: 5px;
  padding-right: 5px;
}
.btn-pulse {
  position: relative;
}
.btn-pulse:after {
  content: "";
  border: 2px solid;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 35px;
  animation: sonar 1.5s ease infinite;
}
.btn-pulse.ant-btn-primary:after {
  border-color: #1b70ff;
}
.btn-pulse.ant-btn-success:after {
  border-color: #0bcf97;
}
.google-link-pulsating {
  position: relative;
  z-index: 1;
  display: inline-block;
  background: #1b70ff !important;
  padding: 5px 10px !important;
  border-radius: 35px !important;
}
.google-link-pulsating a,
.google-link-pulsating .Ico__icon.primary {
  color: #fff !important;
}
.google-link-pulsating:after {
  content: "";
  border: 2px solid #1b70ff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 35px;
  animation: sonarSm 1.5s ease infinite;
  z-index: -1;
}
.ant-btn-inline {
  padding: 0 !important;
  border: none !important;
  box-shadow: none !important;
  display: inline-flex !important;
  align-items: center !important;
  background: transparent !important;
  line-height: 1;
  font-weight: 400;
  height: auto;
}
.ant-btn-inline > i {
  background: transparent !important;
}
.ant-btn-inline > i.danger {
  color: #fa5c7c;
}
h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
  color: #252525;
  margin-top: 0;
  font-weight: 500;
}
/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: transparent !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}
.ant-form label {
  color: rgba(0, 0, 0, 0.75);
}
.ant-form .ant-form-item {
  max-width: 100%;
  margin-bottom: 10px;
}
.ant-form .ant-form-item-extra {
  margin-top: 0.2rem;
}
.ant-form.ant-form-inline .ant-form-item {
  margin-right: 10px;
}
.hidden-label .ant-form-item-label {
  opacity: 0;
}
.ant-form-item-label > label::after {
  content: "";
}
.ant-form-item-required:before {
  font-size: 1.4rem !important;
  line-height: 0;
  padding: 0;
  position: relative;
  top: 0.3rem;
  font-weight: 700;
}
.ant-form-item-explain {
  line-height: 1.2;
  margin: 5px 0 -10px;
}
.hide-all-hints .ant-form-item-explain {
  display: none !important;
}
.ant-select {
  width: 100%;
}
.ant-select-selection.ant-select-selection--multiple {
  border-color: #e8e8e8;
  box-shadow: inset 1px 1px 2px rgba(0, 0, 0, 0.05);
  padding: 0;
  margin: 0;
  min-height: unset;
  max-height: unset;
}
.ant-select-selection.ant-select-selection--multiple ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.ant-select-selection.ant-select-selection--multiple ul li {
  max-width: 140px;
}
.ant-select-loading .ant-select-selection-selected-value {
  opacity: 0 !important;
}
.ant-input,
.ant-picker,
.ant-select .ant-select-selector,
.ant-input-search-icon::before,
.ant-input-affix-wrapper,
.ant-input-search-button,
.ant-radio-button-wrapper,
.ant-radio-button-wrapper:first-child,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #f1f3f6;
}
.ant-input-affix-wrapper.focused {
  box-shadow: 0 0 0 1px #f1f3f6 !important;
}
.ant-radio-group {
  border-radius: 4px;
  display: flex;
  gap: 0 0.4rem;
}
.ant-radio-group label.ant-radio-button-wrapper {
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #f1f3f6;
}
.ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: 0 2px 5px 0 rgba(27, 112, 255, 0.3) !important;
  transform: scale(1);
}
.ant-radio-group .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) i {
  color: #fff;
  opacity: 1;
  font-weight: 700;
}
.ant-select-disabled .ant-select-selection {
  background: #fafafa;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 0;
}
.ant-input-number,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:hover,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #f1f3f6;
  box-shadow: none;
}
.ant-select-multiple .ant-select-selection-item {
  border-radius: 5px;
  border-color: #f1f3f6;
  background: #fff;
}
.ant-input-search-button {
  box-shadow: none !important;
}
.ant-form-item-has-error :not(.ant-input-disabled).ant-input:focus,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:focus,
.ant-form-item-has-error :not(.ant-input-disabled).ant-input-focused,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper-focused {
  box-shadow: none;
}
.ant-table {
  border-radius: 5px;
  background: #fff;
}
.ant-table .ant-table-title {
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  background-color: #1b70ff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  will-change: transform;
  border-radius: 5px 5px 0 0;
  padding: 1.5rem 2rem;
}
.ant-table .ant-table-title:empty {
  transition: all 0.3s ease;
  max-height: 0;
  opacity: 0;
  transform: translateY(50%);
}
.ant-table .ant-table-title:not(:empty) {
  transition: all 0.5s ease 0.4s;
  max-height: 60px;
  box-shadow: inset 0 -2px 5px rgba(0, 0, 0, 0.1);
  transform: translateY(-100%);
}
.ant-table .ant-table-title .ant-btn {
  border-color: transparent !important;
}
.ant-table th.col-actions {
  height: 50px;
}
.ant-table th.ant-table-cell {
  position: relative;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  min-height: 44px;
}
.ant-table th.ant-table-cell .ant-table-filter-column-title {
  padding: 5px 8px;
}
.ant-table td.ant-table-selection-column {
  min-width: 32px;
}
.ant-table .ant-table-selection-column {
  position: relative;
}
.ant-table .ant-table-selection-column .ant-checkbox-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 5px;
}
.ant-table .ant-form-item-control {
  line-height: 1;
}
.ant-table .ant-form-item-control .editable-cell-value-wrap {
  position: relative;
  padding: 0;
}
.ant-table .ant-form-item-control .editable-cell-value-wrap input {
  border: none;
  font-size: 0.8rem;
  box-shadow: none;
  padding: 0;
  margin: 0;
}
.ant-table .ant-table-tbody .ant-table-row:last-child td {
  border: none;
}
.ant-table .ant-table-tbody .ant-table-placeholder td {
  border: none !important;
}
.ant-table .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}
.table-shadowed {
  box-shadow: 0 4px 14px -4px #1558a809, 0 2px 15px -5px rgba(21, 88, 168, 0.15);
  border-radius: 15px;
  overflow: hidden;
}
.table-shadowed .ant-table-thead th {
  font-weight: bold;
  font-size: 1rem;
}
.ant-tabs-ink-bar {
  z-index: 0;
}
th.ant-table-cell:empty {
  padding: 0 !important;
}
tr.active-row {
  position: relative;
  box-shadow: inset 0 0 0 1px #0bcf97, inset 0 -1px 0 1px #0bcf97, 0 2px 5px rgba(11, 207, 151, 0.1);
  background: rgba(11, 207, 151, 0.05) linear-gradient(rgba(11, 207, 151, 0.01), rgba(11, 207, 151, 0.1));
  border-radius: 8px;
}
tr.active-row button[disabled] {
  box-shadow: none;
  color: #0bcf97;
  animation: fadeIn 0.5s ease;
  font-size: 1.5rem;
}
tr.active-row button[disabled],
tr.active-row button[disabled]:hover {
  background: transparent;
  border-color: transparent !important;
}
tr.active-row > td {
  border-bottom: none;
}
.table-condensed .ant-table-row-expand-icon-cell {
  text-align: center;
  padding: 0 !important;
}
.table-condensed .ant-table-row-expand-icon-cell span {
  display: inline-block;
  position: relative;
  top: 3px;
}
.table-condensed td {
  padding: 0 10px !important;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: -16px;
}
.ant-card {
  box-shadow: 0 4px 14px -4px #1558a809, 0 2px 15px -5px rgba(21, 88, 168, 0.15);
  border-radius: 12px;
  margin-bottom: 15px;
  font-size: 1rem;
  overflow: hidden;
}
.ant-card.ant-card-bordered {
  border: none;
}
.ant-card.card-fancy .Ico {
  position: absolute;
  right: 15px;
  bottom: 0;
  font-size: 3rem;
  opacity: 0.4;
}
.ant-card.card-fancy .ant-card-head .ant-btn {
  background: transparent linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.3)) center / 200px;
  box-shadow: -2px -2px 2px 1px rgba(255, 255, 255, 0.2), 2px 2px 4px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1);
  border: none;
}
.ant-card.card-fancy .ant-card-head .ant-btn:hover {
  box-shadow: -4px -4px 8px rgba(255, 255, 255, 0.3), 4px 5px 8px rgba(0, 0, 0, 0.25);
  background: rgba(255, 255, 255, 0.7) linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.3)) center / 200px;
  color: #4983bd;
  text-shadow: 1px 1px 0 #ffffff;
}
.ant-card.card-fancy,
.ant-card.card-fancy .ant-card-head {
  color: #fff;
  border-bottom-color: rgba(255, 255, 255, 0.3);
}
.ant-card .card-icon {
  position: absolute;
  right: 15px;
  top: 60px;
  font-size: 3rem;
  opacity: 0.4;
}
.ant-card.card-green {
  background: #5ae7c5;
  background: url(/media/img/waves.svg) center left / cover, linear-gradient(45deg, #3adcb5, #bac4fe);
}
.ant-card.card-red {
  background: #eb3b71;
  background: url(/media/img/waves.svg) center left / cover, linear-gradient(45deg, #eb3b71, #fd7e8a);
}
.ant-card.card-purple {
  background-color: #eb3b71;
  background: url(/media/img/waves.svg) center left / cover, linear-gradient(45deg, #6597ff, #a570f7);
}
.ant-card.card-blue {
  background-color: #70b2f7;
  background: url(/media/img/waves.svg) center left / cover, linear-gradient(45deg, #70b2f7, #788bfc);
}
.ant-card.card-white,
.ant-card.card-white .ant-card-head {
  color: #000;
  border-bottom-color: rgba(27, 112, 255, 0.1);
}
.ant-card.card-white .ant-card-head .ant-btn {
  background: transparent linear-gradient(rgba(255, 255, 255, 0.8), rgba(27, 112, 255, 0.1)) center / 200px;
  box-shadow: -2px -2px 2px 1px rgba(255, 255, 255, 0.2), 2px 2px 4px #6195cc69;
  color: #1b70ff;
}
.ant-card.card-white .ant-card-head .ant-btn:hover {
  box-shadow: -4px -4px 8px rgba(255, 255, 255, 0.3), 4px 5px 8px rgba(97, 149, 204, 0.25);
}
.ant-card.card-black {
  background-color: #c5d8df;
  background: linear-gradient(45deg, #c5d8df, #d7dbe5);
}
.ant-card.ant-card-small .ant-card-head {
  min-height: unset;
  font-weight: 700;
  padding-left: 1rem;
}
.ant-card.ant-card-small .ant-card-head-title {
  padding: 1rem;
}
.ant-card .ant-card-head-title {
  overflow: visible !important;
}
.ant-card-body .ant-table-thead > tr > th {
  background: transparent;
  font-weight: 700;
}
.card.ant-empty {
  margin: 0;
}
.ant-badge {
  will-change: transform;
  z-index: 1;
}
.ant-badge .ant-scroll-number-only > p.ant-scroll-number-only-unit {
  font-family: sans-serif;
  font-weight: 700;
  font-size: 0.8rem;
}
.ant-badge .ant-badge-count {
  box-shadow: 0 2px 3px rgba(250, 92, 124, 0.5);
  font-family: sans-serif;
  font-weight: 600;
}
.ant-badge.badge-percent .ant-badge-count {
  box-shadow: none;
  background: transparent;
  color: #252525;
  padding-right: 1rem;
  border-radius: 0;
  line-height: 1;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-align: left;
  padding: 0 0.9rem 0 0;
}
.ant-badge.badge-percent .ant-badge-count:after {
  position: absolute;
  content: "%";
  font-size: 0.9rem;
  top: 1px;
  right: 0;
}
.ant-badge.badge-percent .ant-badge-count p {
  font-size: 1rem;
  font-weight: 500;
  color: #1b70ff;
}
.ant-badge.badge-primary .ant-badge-count {
  background: #1b70ff;
  box-shadow: 0 2px 4px rgba(27, 112, 255, 0.3) !important;
  font-weight: 700;
  color: #fff;
}
.ant-badge.badge-primary .ant-badge-count p {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.9);
}
.ant-badge.small {
  transform: scale(0.8);
}
.ant-badge.small sup.ant-badge-count {
  font-weight: bold !important;
  font-size: 0.1rem !important;
}
.ant-tabs-nav .ant-tabs-tab-active {
  font-weight: normal;
}
.ant-tabs-nav .Ico__text {
  margin-left: 0.3rem;
}
.ant-tabs .ant-tabs-tab-btn i {
  color: #1b70ff;
  transition: all 0.2s ease;
  font-size: 18px;
  position: relative;
  top: 2px;
}
.ant-tabs .ant-tabs-content {
  min-height: 100px;
}
.ChangeDueDatePopover.clickable {
  position: relative;
}
.ChangeDueDatePopover.clickable .Ico__text time::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes like {
  from {
    transform: scale(0.9) rotate(-5);
  }
  70% {
    transform: scale(1.3) rotate(-15deg) translateY(-2px);
  }
  to {
    transform: scale(1);
  }
}
@keyframes fadeInLeftUp {
  from {
    opacity: 0;
    transform: translate(-100px, 100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100px);
  }
}
@keyframes rotateNext {
  from {
    transform: rotate(45deg);
  }
  to {
    transform: rotate(0);
  }
}
@keyframes rotatePrev {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(45deg);
  }
}
@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 0;
  }
  40% {
    transform: scale(1.2);
    opacity: 1;
  }
  to {
    transform: scale(1.4);
    opacity: 0;
  }
}
@keyframes jelloHorizontal {
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.15, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.rotate-next {
  animation: rotateNext 0.2s linear;
}
.rotate-prev {
  animation: rotatePrev 0.2s linear reverse;
}
.zoomIn {
  animation: zoomIn 0.4s ease-in-out both;
}
.fadeIn {
  animation: fadeIn 0.4s ease-in-out both;
}
.fadeOut {
  animation: fadeOut 0.4s ease-in-out both;
}
.fadeInUp {
  animation: fadeInUp 0.4s ease-in-out both;
}
.fadeInLeft {
  animation: fadeInLeft 0.4s ease-in-out both;
}
.spin-fast {
  animation: fa-spin 0.65s linear infinite;
}
.fa-spin {
  animation-duration: 0.4s;
}
@keyframes sonar {
  from {
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(1.5);
  }
}
@keyframes sonarSm {
  from {
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(1.1);
  }
}
/* nunito-300 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 300;
  src: local(""), url(/media/fonts/nunito/nunito-v16-latin-300.woff2) format("woff2"), url(/media/fonts/nunito/nunito-v16-latin-300.woff) format("woff");
}
/* nunito-regular - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  src: local(""), url(/media/fonts/nunito/nunito-v16-latin-regular.woff2) format("woff2"), url(/media/fonts/nunito/nunito-v16-latin-regular.woff) format("woff");
}
/* nunito-600 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  src: local(""), url(/media/fonts/nunito/nunito-v16-latin-600.woff2) format("woff2"), url(/media/fonts/nunito/nunito-v16-latin-600.woff) format("woff");
}
/* nunito-700 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  src: local(""), url(/media/fonts/nunito/nunito-v16-latin-700.woff2) format("woff2"), url(/media/fonts/nunito/nunito-v16-latin-700.woff) format("woff");
}
/* nunito-800 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 800;
  src: local(""), url(/media/fonts/nunito/nunito-v16-latin-800.woff2) format("woff2"), url(/media/fonts/nunito/nunito-v16-latin-800.woff) format("woff");
}
/* nunito-900 - latin */
@font-face {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 900;
  src: local(""), url(/media/fonts/nunito/nunito-v16-latin-900.woff2) format("woff2"), url(/media/fonts/nunito/nunito-v16-latin-900.woff) format("woff");
}
html,
body {
  margin: 0;
  background: #fafbfb;
}
body {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
h1 {
  margin-bottom: 1rem;
}
h2,
h3,
h4,
h5,
h6 {
  margin: 1rem 0 0.5rem;
}
ul {
  padding: 0;
  margin: 0;
}
a {
  color: #252525;
}
hr {
  opacity: 0.2;
}
label {
  user-select: none;
}
a.active {
  color: #1b70ff;
}
:focus {
  outline: none;
}
.visible-on-small {
  display: none;
}
@media (max-width: 640px) {
  .visible-on-small {
    display: block;
  }
}
.subheading {
  margin-bottom: 2rem;
  font-size: 1rem;
}
.currency {
  font-weight: 700;
  white-space: nowrap;
}
.currency[data-value="0"] {
  color: #fa5c7c;
}
.currency:before {
  font-weight: 300;
}
td .currency:before {
  opacity: 0.3;
}
.currency.currency-EUR:before {
  content: "€ ";
}
.currency.currency-USD:before {
  content: "$ ";
}
.currency.invert {
  color: #fff;
}
.currency.invert:before {
  color: rgba(255, 255, 255, 0.3);
}
.list-numbered {
  margin: 0;
  padding-left: 2ch;
}
.link {
  position: relative;
  transition: all 0.2s ease;
  color: #1b70ff;
}
.link:hover {
  border-color: transparent;
}
.upper-first {
  text-transform: lowercase;
}
.upper-first::first-letter {
  text-transform: uppercase;
}
span.upper-first {
  display: inline-block;
}
.ErrorServer {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Ico__text {
  margin-left: 0.3rem;
}
.Ico__icon.black {
  background: transparent;
  color: #222;
}
.Ico__icon.danger,
.Ico__icon.success {
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
}
.Ico__icon.muted {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 100;
}
.Ico__icon.pink {
  background: transparent;
  color: #ff57a3;
}
.Ico__icon.danger,
.Ico__icon.success {
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
}
.Ico__icon.muted {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 100;
}
.Ico__icon.warning {
  background: transparent;
  color: #ffbc02;
}
.Ico__icon.danger,
.Ico__icon.success {
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
}
.Ico__icon.muted {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 100;
}
.Ico__icon.info {
  background: transparent;
  color: #3190ff;
}
.Ico__icon.danger,
.Ico__icon.success {
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
}
.Ico__icon.muted {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 100;
}
.Ico__icon.success {
  background: transparent;
  color: #0bcf97;
}
.Ico__icon.danger,
.Ico__icon.success {
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
}
.Ico__icon.muted {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 100;
}
.Ico__icon.danger {
  background: transparent;
  color: #fa5c7c;
}
.Ico__icon.danger,
.Ico__icon.success {
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
}
.Ico__icon.muted {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 100;
}
.Ico__icon.mint {
  background: transparent;
  color: #50d8e3;
}
.Ico__icon.danger,
.Ico__icon.success {
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
}
.Ico__icon.muted {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 100;
}
.Ico__icon.primary {
  background: transparent;
  color: #1b70ff;
}
.Ico__icon.danger,
.Ico__icon.success {
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  text-rendering: auto;
}
.Ico__icon.muted {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 100;
}
.Ico__icon.inline {
  display: inline;
}
.opacity {
  opacity: 0.5;
}
.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}
.flex {
  display: flex;
}
.flex-center {
  display: flex;
  align-items: center;
  gap: 5px;
}
.clickable {
  cursor: pointer;
}
.inactive {
  opacity: 0.5;
}
.inactive:hover {
  opacity: 1;
}
.hide {
  display: none !important;
}
.visibility-hidden {
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  position: absolute;
}
.light-shadows {
  box-shadow: 0 4px 14px -4px #1558a809, 0 2px 15px -5px rgba(21, 88, 168, 0.15);
}
.tiny-shadows {
  box-shadow: 0 4px 14px -4px #1558a809, 0 2px 10px -5px rgba(21, 88, 168, 0.25);
}
.text-overflow {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.brand-icons i.fal {
  color: #1890ff;
  background: #e6f7ff;
  border-radius: 50%;
  margin-right: 0.3rem;
  align-self: flex-start;
  min-width: 1ch;
  text-align: center;
}
.brand-icons i.fal.black {
  background: rgba(34, 34, 34, 0.1);
  color: #222;
}
.brand-icons i.fal.pink {
  background: rgba(255, 87, 163, 0.1);
  color: #ff57a3;
}
.brand-icons i.fal.warning {
  background: rgba(255, 188, 2, 0.1);
  color: #ffbc02;
}
.brand-icons i.fal.info {
  background: rgba(49, 144, 255, 0.1);
  color: #3190ff;
}
.brand-icons i.fal.success {
  background: rgba(11, 207, 151, 0.1);
  color: #0bcf97;
}
.brand-icons i.fal.danger {
  background: rgba(250, 92, 124, 0.1);
  color: #fa5c7c;
}
.brand-icons i.fal.mint {
  background: rgba(80, 216, 227, 0.1);
  color: #50d8e3;
}
.brand-icons i.fal.primary {
  background: rgba(27, 112, 255, 0.1);
  color: #1b70ff;
}
.interactive {
  cursor: pointer;
  transition: all 0.2s ease;
}
.interactive:hover {
  box-shadow: 0 4px 14px -4px rgba(54, 111, 180, 0.2), 0 2px 25px -5px rgba(54, 111, 180, 0.1), 0 1px 1px rgba(54, 111, 180, 0.05);
}
.interactive:active {
  transform: translateY(1px);
  transition: transform 0.1s;
  box-shadow: 0 1px 3px -1px rgba(21, 88, 168, 0.1);
}
.ant-card-selected {
  border: 1px solid #1b70ff !important;
}
.locked {
  user-select: none;
  pointer-events: none;
}
.disabled {
  opacity: 0.5 !important;
  user-select: none;
  pointer-events: none;
}
.noselect {
  user-select: none;
}
.pull-right {
  float: right;
}
.text-xs {
  font-size: 0.85rem;
  line-height: 1.2;
}
.text-muted {
  opacity: 0.65;
}
.text-strong {
  font-weight: bold;
}
tr > td.cell-xs {
  padding: 0 0.5rem;
}
ul.ant-timeline {
  padding-top: 5px;
}
ul.ant-timeline li.ant-timeline-item:last-child {
  padding-bottom: 0;
}
ul.ant-timeline li.ant-timeline-item:last-child .ant-timeline-item-content {
  min-height: unset;
}
ul.ant-timeline li.ant-timeline-item .ant-timeline-item-content {
  padding-top: 2px;
}
.tag,
.ant-tag {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
}
.tag.tag-dot,
.ant-tag.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.tag-muted {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.bonusPaymentTypes.status-1 {
  color: #222;
  box-shadow: 0 0 0 1px rgba(34, 34, 34, 0.5);
}
.bonusPaymentTypes.status-2 {
  color: #222;
  box-shadow: 0 0 0 1px rgba(34, 34, 34, 0.5);
}
.bonusPaymentTypes.status-3 {
  color: #222;
  box-shadow: 0 0 0 1px rgba(34, 34, 34, 0.5);
}
.google-doc-link,
.jira-link {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.userRoles {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
}
.userRoles.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.userRoles.role-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.userRoles.role-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.userRoles.role-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #3190ff;
  box-shadow: 0 2px 4px rgba(49, 144, 255, 0.5);
}
.userRoles.role-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.userRoles.role-3 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #50d8e3;
  box-shadow: 0 2px 4px rgba(80, 216, 227, 0.5);
}
.userRoles.role-3.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.PontConvertersCards__inner {
  display: flex;
}
.PontConvertersCards__inner > *:last-child .ant-tag {
  font-weight: 700;
}
.priceSettingStatuses,
.linkPlanStatuses {
  font-weight: 700;
}
.referringLinkTypes {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.referringLinkRelevanceStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.referringLinkRelevanceStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringLinkRelevanceStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.referringLinkRelevanceStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringLinkRelevanceStatuses.status-3 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.referringLinkRelevanceStatuses.status-3.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringLinkRelevanceStatuses.status-4 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.referringLinkRelevanceStatuses.status-4.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringLinkRelevanceStatuses.status-5 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.referringLinkRelevanceStatuses.status-5.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringLinkRelevanceStatuses.status-6 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.referringLinkRelevanceStatuses.status-6.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.taskStatus.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.taskStatus.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.taskStatus.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #1b70ff;
  box-shadow: 0 2px 4px rgba(27, 112, 255, 0.5);
}
.taskStatus.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.outreachTaskTypes.type-1 {
  color: #3190ff;
  box-shadow: 0 0 0 1px rgba(49, 144, 255, 0.5);
}
.outreachTaskTypes.type-2 {
  color: #3190ff;
  box-shadow: 0 0 0 1px rgba(49, 144, 255, 0.5);
}
.referringLinkIssueStatus.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.referringLinkIssueStatus.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.ant-tag-green {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.ant-tag-green.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.TagResponseCode {
  font-size: 11px !important;
}
.TagResponseCode.code-20s {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.TagResponseCode.code-20s.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.TagResponseCode.code-40s {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.TagResponseCode.code-40s.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.TagResponseCode.code-50s {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.TagResponseCode.code-50s.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.tag-danger {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.tag-danger.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.RefDomainsTable__status .ant-tag,
.TasksTable__status .ant-tag {
  text-align: center;
  margin: 0 auto;
}
.referringDomainPenaltyStatuses.status-1,
.fileStatuses.status-1,
.webmasterStatuses.status-1,
.userStatuses.status-1,
.thematicConformityStatuses.status-1,
.submissionTypeStatuses.status-1,
.siteStatuses.status-1,
.priceSettingStatuses.status-1,
.linkAttributeStatuses.status-1,
.landingStatuses.status-1,
.donatedSiteStatuses.status-1,
.donatedLandingStatuses.status-1,
.organicnessStatuses.status-1,
.teamStatuses.status-1,
.anchorStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.referringDomainPenaltyStatuses.status-1.tag-dot,
.fileStatuses.status-1.tag-dot,
.webmasterStatuses.status-1.tag-dot,
.userStatuses.status-1.tag-dot,
.thematicConformityStatuses.status-1.tag-dot,
.submissionTypeStatuses.status-1.tag-dot,
.siteStatuses.status-1.tag-dot,
.priceSettingStatuses.status-1.tag-dot,
.linkAttributeStatuses.status-1.tag-dot,
.landingStatuses.status-1.tag-dot,
.donatedSiteStatuses.status-1.tag-dot,
.donatedLandingStatuses.status-1.tag-dot,
.organicnessStatuses.status-1.tag-dot,
.teamStatuses.status-1.tag-dot,
.anchorStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringDomainPenaltyStatuses.status-2,
.fileStatuses.status-2,
.webmasterStatuses.status-2,
.userStatuses.status-2,
.thematicConformityStatuses.status-2,
.submissionTypeStatuses.status-2,
.siteStatuses.status-2,
.priceSettingStatuses.status-2,
.linkAttributeStatuses.status-2,
.landingStatuses.status-2,
.donatedSiteStatuses.status-2,
.donatedLandingStatuses.status-2,
.organicnessStatuses.status-2,
.teamStatuses.status-2,
.anchorStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.referringDomainPenaltyStatuses.status-2.tag-dot,
.fileStatuses.status-2.tag-dot,
.webmasterStatuses.status-2.tag-dot,
.userStatuses.status-2.tag-dot,
.thematicConformityStatuses.status-2.tag-dot,
.submissionTypeStatuses.status-2.tag-dot,
.siteStatuses.status-2.tag-dot,
.priceSettingStatuses.status-2.tag-dot,
.linkAttributeStatuses.status-2.tag-dot,
.landingStatuses.status-2.tag-dot,
.donatedSiteStatuses.status-2.tag-dot,
.donatedLandingStatuses.status-2.tag-dot,
.organicnessStatuses.status-2.tag-dot,
.teamStatuses.status-2.tag-dot,
.anchorStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringDomainPenaltyStatuses.status-3,
.fileStatuses.status-3,
.webmasterStatuses.status-3,
.userStatuses.status-3,
.thematicConformityStatuses.status-3,
.submissionTypeStatuses.status-3,
.siteStatuses.status-3,
.priceSettingStatuses.status-3,
.linkAttributeStatuses.status-3,
.landingStatuses.status-3,
.donatedSiteStatuses.status-3,
.donatedLandingStatuses.status-3,
.organicnessStatuses.status-3,
.teamStatuses.status-3,
.anchorStatuses.status-3 {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.referringDomainPenaltyStatuses.status-4,
.fileStatuses.status-4,
.webmasterStatuses.status-4,
.userStatuses.status-4,
.thematicConformityStatuses.status-4,
.submissionTypeStatuses.status-4,
.siteStatuses.status-4,
.priceSettingStatuses.status-4,
.linkAttributeStatuses.status-4,
.landingStatuses.status-4,
.donatedSiteStatuses.status-4,
.donatedLandingStatuses.status-4,
.organicnessStatuses.status-4,
.teamStatuses.status-4,
.anchorStatuses.status-4 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.referringDomainPenaltyStatuses.status-4.tag-dot,
.fileStatuses.status-4.tag-dot,
.webmasterStatuses.status-4.tag-dot,
.userStatuses.status-4.tag-dot,
.thematicConformityStatuses.status-4.tag-dot,
.submissionTypeStatuses.status-4.tag-dot,
.siteStatuses.status-4.tag-dot,
.priceSettingStatuses.status-4.tag-dot,
.linkAttributeStatuses.status-4.tag-dot,
.landingStatuses.status-4.tag-dot,
.donatedSiteStatuses.status-4.tag-dot,
.donatedLandingStatuses.status-4.tag-dot,
.organicnessStatuses.status-4.tag-dot,
.teamStatuses.status-4.tag-dot,
.anchorStatuses.status-4.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.userStatuses.status-5 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.userStatuses.status-5.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.submissionTypeFeaturesStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #3190ff;
  box-shadow: 0 2px 4px rgba(49, 144, 255, 0.5);
}
.submissionTypeFeaturesStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.submissionTypeFeaturesStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}
.submissionTypeFeaturesStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.submissionTypeFeaturesStatuses.status-3 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.submissionTypeFeaturesStatuses.status-3.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.submissionTypeFeaturesStatuses.status-4 {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.feedbackStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.feedbackStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.feedbackStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #3190ff;
  box-shadow: 0 2px 4px rgba(49, 144, 255, 0.5);
}
.feedbackStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.feedbackStatuses.status-3 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.feedbackStatuses.status-3.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.feedbackStatuses.status-4,
.feedbackStatuses.status-6 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.feedbackStatuses.status-4.tag-dot,
.feedbackStatuses.status-6.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.feedbackStatuses.status-5 {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.financialPenaltyStatuses.status-1,
.bonusPaymentStatuses.status-1,
.attainmentPaymentStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.financialPenaltyStatuses.status-1.tag-dot,
.bonusPaymentStatuses.status-1.tag-dot,
.attainmentPaymentStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.financialPenaltyStatuses.status-2,
.bonusPaymentStatuses.status-2,
.attainmentPaymentStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #3190ff;
  box-shadow: 0 2px 4px rgba(49, 144, 255, 0.5);
}
.financialPenaltyStatuses.status-2.tag-dot,
.bonusPaymentStatuses.status-2.tag-dot,
.attainmentPaymentStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.financialPenaltyStatuses.status-3,
.bonusPaymentStatuses.status-3,
.attainmentPaymentStatuses.status-3 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.financialPenaltyStatuses.status-3.tag-dot,
.bonusPaymentStatuses.status-3.tag-dot,
.attainmentPaymentStatuses.status-3.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.financialPenaltyStatuses.status-4,
.bonusPaymentStatuses.status-4,
.attainmentPaymentStatuses.status-4,
.financialPenaltyStatuses.status-5,
.bonusPaymentStatuses.status-5,
.attainmentPaymentStatuses.status-5 {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.financialPenaltyStatuses.status-6,
.bonusPaymentStatuses.status-6,
.attainmentPaymentStatuses.status-6,
.financialPenaltyStatuses.status-7,
.bonusPaymentStatuses.status-7,
.attainmentPaymentStatuses.status-7 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.financialPenaltyStatuses.status-6.tag-dot,
.bonusPaymentStatuses.status-6.tag-dot,
.attainmentPaymentStatuses.status-6.tag-dot,
.financialPenaltyStatuses.status-7.tag-dot,
.bonusPaymentStatuses.status-7.tag-dot,
.attainmentPaymentStatuses.status-7.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.teamUserStatuses.status-1,
.messageStatuses.status-1,
.commentStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.teamUserStatuses.status-1.tag-dot,
.messageStatuses.status-1.tag-dot,
.commentStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.teamUserStatuses.status-2,
.messageStatuses.status-2,
.commentStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.teamUserStatuses.status-2.tag-dot,
.messageStatuses.status-2.tag-dot,
.commentStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringPageIssueStatuses.status-1,
.referringLinkIssueStatuses.status-1,
.referringDomainIssueStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.referringPageIssueStatuses.status-1.tag-dot,
.referringLinkIssueStatuses.status-1.tag-dot,
.referringDomainIssueStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringPageIssueStatuses.status-2,
.referringLinkIssueStatuses.status-2,
.referringDomainIssueStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.referringPageIssueStatuses.status-2.tag-dot,
.referringLinkIssueStatuses.status-2.tag-dot,
.referringDomainIssueStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.paymentOptionStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.paymentOptionStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.paymentOptionStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.paymentOptionStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.issueStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.issueStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.issueStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.issueStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.linkPlanStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.linkPlanStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.linkPlanStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #3190ff;
  box-shadow: 0 2px 4px rgba(49, 144, 255, 0.5);
}
.linkPlanStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.linkPlanStatuses.status-3 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #50d8e3;
  box-shadow: 0 2px 4px rgba(80, 216, 227, 0.5);
}
.linkPlanStatuses.status-3.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.linkPlanStatuses.status-4 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.linkPlanStatuses.status-4.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.linkPlanStatuses.status-5,
.linkPlanStatuses.status-6 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.linkPlanStatuses.status-5.tag-dot,
.linkPlanStatuses.status-6.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.linkPlanStatuses.status-7 {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.thematicConformityLevels.status-1,
.organicnessLevels.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.thematicConformityLevels.status-1.tag-dot,
.organicnessLevels.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.thematicConformityLevels.status-2,
.organicnessLevels.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #3190ff;
  box-shadow: 0 2px 4px rgba(49, 144, 255, 0.5);
}
.thematicConformityLevels.status-2.tag-dot,
.organicnessLevels.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.thematicConformityLevels.status-3,
.organicnessLevels.status-3 {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.thematicConformityLevels.status-4,
.organicnessLevels.status-4 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.thematicConformityLevels.status-4.tag-dot,
.organicnessLevels.status-4.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.thematicConformityLevels.status-5,
.organicnessLevels.status-5 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.thematicConformityLevels.status-5.tag-dot,
.organicnessLevels.status-5.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.paymentDetailStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.paymentDetailStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.paymentDetailStatuses.status-2,
.paymentDetailStatuses.status-3,
.paymentDetailStatuses.status-7 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.paymentDetailStatuses.status-2.tag-dot,
.paymentDetailStatuses.status-3.tag-dot,
.paymentDetailStatuses.status-7.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.paymentDetailStatuses.status-4 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.paymentDetailStatuses.status-4.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.paymentDetailStatuses.status-5,
.paymentDetailStatuses.status-6 {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.referringDomainStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.referringDomainStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringDomainStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.referringDomainStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringDomainStatuses.status-3 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.referringDomainStatuses.status-3.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringLinkStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.referringLinkStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringLinkStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.referringLinkStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringLinkStatuses.status-3 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.referringLinkStatuses.status-3.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringLinkStatuses.status-4 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.referringLinkStatuses.status-4.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringLinkStatuses.status-5 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.referringLinkStatuses.status-5.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.customEffortStatuses.status-1,
.submissionContentStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.customEffortStatuses.status-1.tag-dot,
.submissionContentStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.customEffortStatuses.status-2,
.submissionContentStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #50d8e3;
  box-shadow: 0 2px 4px rgba(80, 216, 227, 0.5);
}
.customEffortStatuses.status-2.tag-dot,
.submissionContentStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.customEffortStatuses.status-3,
.submissionContentStatuses.status-3 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.customEffortStatuses.status-3.tag-dot,
.submissionContentStatuses.status-3.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.customEffortStatuses.status-4,
.submissionContentStatuses.status-4 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.customEffortStatuses.status-4.tag-dot,
.submissionContentStatuses.status-4.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.customEffortStatuses.status-5,
.submissionContentStatuses.status-5,
.customEffortStatuses.status-8,
.submissionContentStatuses.status-8 {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.customEffortStatuses.status-6,
.submissionContentStatuses.status-6,
.customEffortStatuses.status-7,
.submissionContentStatuses.status-7 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.customEffortStatuses.status-6.tag-dot,
.submissionContentStatuses.status-6.tag-dot,
.customEffortStatuses.status-7.tag-dot,
.submissionContentStatuses.status-7.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.submissionPaymentStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.submissionPaymentStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.submissionPaymentStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #3190ff;
  box-shadow: 0 2px 4px rgba(49, 144, 255, 0.5);
}
.submissionPaymentStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.submissionPaymentStatuses.status-3 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.submissionPaymentStatuses.status-3.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.submissionPaymentStatuses.status-4 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
}
.submissionPaymentStatuses.status-4.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.submissionPaymentStatuses.status-5 {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.submissionPaymentStatuses.status-6,
.submissionPaymentStatuses.status-7 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.submissionPaymentStatuses.status-6.tag-dot,
.submissionPaymentStatuses.status-7.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.submissionTypeNatures.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.submissionTypeNatures.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.submissionTypeNatures.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #3190ff;
  box-shadow: 0 2px 4px rgba(49, 144, 255, 0.5);
}
.submissionTypeNatures.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.submissionTypeNatures.status-3 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.submissionTypeNatures.status-3.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.submissionTypeNatures.status-4 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #50d8e3;
  box-shadow: 0 2px 4px rgba(80, 216, 227, 0.5);
}
.submissionTypeNatures.status-4.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.submissionTypeNatures.status-5 {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.submissionTypeNatures.status-6 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.submissionTypeNatures.status-6.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.attainmentPaymentBatchStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.attainmentPaymentBatchStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.attainmentPaymentBatchStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.attainmentPaymentBatchStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.attainmentPaymentBatchStatuses.status-3 {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.referringDomainTypes {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.taskDonatedLandingStatuses.status-1,
.taskSubmissionPriceStatuses.status-1,
.taskSubmissionTypeStatuses.status-1,
.taskReferringPageStatuses.status-1,
.taskReferringLinkStatuses.status-1,
.taskReferringDomainStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.taskDonatedLandingStatuses.status-1.tag-dot,
.taskSubmissionPriceStatuses.status-1.tag-dot,
.taskSubmissionTypeStatuses.status-1.tag-dot,
.taskReferringPageStatuses.status-1.tag-dot,
.taskReferringLinkStatuses.status-1.tag-dot,
.taskReferringDomainStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.taskDonatedLandingStatuses.status-2,
.taskSubmissionPriceStatuses.status-2,
.taskSubmissionTypeStatuses.status-2,
.taskReferringPageStatuses.status-2,
.taskReferringLinkStatuses.status-2,
.taskReferringDomainStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.taskDonatedLandingStatuses.status-2.tag-dot,
.taskSubmissionPriceStatuses.status-2.tag-dot,
.taskSubmissionTypeStatuses.status-2.tag-dot,
.taskReferringPageStatuses.status-2.tag-dot,
.taskReferringLinkStatuses.status-2.tag-dot,
.taskReferringDomainStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.taskDonatedLandingStatuses.status-3,
.taskSubmissionPriceStatuses.status-3,
.taskSubmissionTypeStatuses.status-3,
.taskReferringPageStatuses.status-3,
.taskReferringLinkStatuses.status-3,
.taskReferringDomainStatuses.status-3 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.taskDonatedLandingStatuses.status-3.tag-dot,
.taskSubmissionPriceStatuses.status-3.tag-dot,
.taskSubmissionTypeStatuses.status-3.tag-dot,
.taskReferringPageStatuses.status-3.tag-dot,
.taskReferringLinkStatuses.status-3.tag-dot,
.taskReferringDomainStatuses.status-3.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.taskStatuses.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.taskStatuses.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.taskStatuses.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #3190ff;
  box-shadow: 0 2px 4px rgba(49, 144, 255, 0.5);
}
.taskStatuses.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.taskStatuses.status-3 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #50d8e3;
  box-shadow: 0 2px 4px rgba(80, 216, 227, 0.5);
}
.taskStatuses.status-3.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.taskStatuses.status-4 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.taskStatuses.status-4.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.taskStatuses.status-5 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.taskStatuses.status-5.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.taskStatuses.status-6,
.taskStatuses.status-7 {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.taskStatuses.status-8 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.taskStatuses.status-8.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.taskStatuses.status-9 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #3190ff;
  box-shadow: 0 2px 4px rgba(49, 144, 255, 0.5);
}
.taskStatuses.status-9.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.userOnboardingStages.status-1 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.userOnboardingStages.status-1.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.userOnboardingStages.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #3190ff;
  box-shadow: 0 2px 4px rgba(49, 144, 255, 0.5);
}
.userOnboardingStages.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.userOnboardingStages.status-3 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.userOnboardingStages.status-3.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.userOnboardingStages.status-4 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.userOnboardingStages.status-4.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.userOnboardingStages.status-5 {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.referringDomainContactStages.status-1 {
  border: 1px solid rgba(128, 128, 128, 0.4);
  background: #fff;
  color: grey;
}
.referringDomainContactStages.status-2 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #50d8e3;
  box-shadow: 0 2px 4px rgba(80, 216, 227, 0.5);
}
.referringDomainContactStages.status-2.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringDomainContactStages.status-3 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #3190ff;
  box-shadow: 0 2px 4px rgba(49, 144, 255, 0.5);
}
.referringDomainContactStages.status-3.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringDomainContactStages.status-4 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #3190ff;
  box-shadow: 0 2px 4px rgba(49, 144, 255, 0.5);
}
.referringDomainContactStages.status-4.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringDomainContactStages.status-5 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.referringDomainContactStages.status-5.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringDomainContactStages.status-6 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.referringDomainContactStages.status-6.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringDomainContactStages.status-7 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #ffbc02;
  box-shadow: 0 2px 4px rgba(255, 188, 2, 0.5);
}
.referringDomainContactStages.status-7.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringDomainContactStages.status-8 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.5);
}
.referringDomainContactStages.status-8.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.referringDomainContactStages.status-9 {
  border-radius: 4px;
  font-weight: 700;
  line-height: 1;
  padding: 2px 3px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background: #fafafa;
  font-size: 13px;
  color: #222;
  border: none;
  margin: 0;
  color: #fff;
  background: #0bcf97;
  box-shadow: 0 2px 4px rgba(11, 207, 151, 0.5);
}
.referringDomainContactStages.status-9.tag-dot {
  font-size: 9px;
  overflow: hidden;
  color: rgba(255, 255, 255, 0) !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.landingTypes {
  color: #222;
  box-shadow: 0 0 0 1px rgba(34, 34, 34, 0.5);
}
.taskTypes {
  color: #222;
  box-shadow: 0 0 0 1px rgba(34, 34, 34, 0.5);
}
.submissionContentTypes,
.attainmentPaymentTypes,
.attainmentPaymentBonusTypes {
  color: #222;
  box-shadow: 0 0 0 1px rgba(34, 34, 34, 0.5);
}
.taskRefDomainDangerTag {
  color: #fa5c7c;
  box-shadow: 0 0 0 1px rgba(250, 92, 124, 0.5);
  background-color: #fff;
}
.card {
  box-shadow: 0 4px 14px -4px #1558a809, 0 2px 15px -5px rgba(21, 88, 168, 0.15);
  border-radius: 12px;
  padding: 0.9rem;
  background: #fff;
  transition: all 0.15s ease;
  word-break: break-word;
}
.card.ant-card .ant-card-body {
  padding: 0;
}
.card-default {
  box-shadow: 0 4px 14px -4px #1558a809, 0 2px 15px -5px rgba(21, 88, 168, 0.15);
  border-radius: 12px;
  padding: 0.9rem;
  background: #fff;
  transition: all 0.15s ease;
  word-break: break-word;
  display: inline-flex;
  flex-flow: column;
  gap: 0.5rem;
}
.card-default.ant-empty {
  margin: 0;
}
.card-default.ant-card .ant-card-body {
  padding: 0;
}
.card-default header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  line-height: 1;
  font-size: 1rem;
  font-weight: 700;
  gap: 1rem;
}
.card-default header div::first-letter {
  text-transform: uppercase;
}
.card-default main {
  flex: 1;
  word-break: break-word;
}
.card-default footer {
  font-size: 0.9rem;
  border-top: 1px dashed rgba(192, 192, 192, 0.3);
  margin-top: 0.3rem;
  padding-top: 0.3rem;
}
.card-default footer:empty {
  display: none;
}
.card-default.card-blue {
  padding: 1rem;
  border: 1px solid rgba(27, 112, 255, 0.1);
  box-shadow: 2px 2px 2px rgba(234, 243, 255, 0.5);
  border-radius: 12px;
  background: #f6faff;
  display: flex;
  flex-flow: column;
  position: relative;
  color: #47698e;
}
.card-xs {
  box-shadow: 0 4px 14px -4px #1558a809, 0 2px 15px -5px rgba(21, 88, 168, 0.15);
  border-radius: 12px;
  padding: 0.2rem 0.5rem;
  background: #fff;
}
.card-lg {
  box-shadow: 0 4px 14px -4px #1558a809, 0 2px 15px -5px rgba(21, 88, 168, 0.15);
  border-radius: 12px;
  padding: 0.9rem;
  background: #fff;
  transition: all 0.15s ease;
  word-break: break-word;
  padding: 3rem 4rem;
}
.card-lg.ant-empty {
  margin: 0;
}
.card-lg.ant-card .ant-card-body {
  padding: 0;
}
.card-active {
  box-shadow: 0 0 0 1px #1b70ff, 0 3px 5px -2px rgba(27, 112, 255, 0.1), 0 0 5px 1px rgba(27, 112, 255, 0.3) !important;
}
.card-interactive {
  box-shadow: 0 4px 14px -4px #1558a809, 0 2px 15px -5px rgba(21, 88, 168, 0.15);
  border-radius: 12px;
  padding: 0.9rem;
  background: #fff;
  transition: all 0.15s ease;
  word-break: break-word;
  cursor: pointer;
  transition: all 0.2s ease;
}
.card-interactive.ant-empty {
  margin: 0;
}
.card-interactive.ant-card .ant-card-body {
  padding: 0;
}
.card-interactive:hover {
  box-shadow: 0 4px 14px -4px rgba(54, 111, 180, 0.2), 0 2px 25px -5px rgba(54, 111, 180, 0.1), 0 1px 1px rgba(54, 111, 180, 0.05);
}
.card-interactive:active {
  transform: translateY(1px);
  transition: transform 0.1s;
  box-shadow: 0 1px 3px -1px rgba(21, 88, 168, 0.1);
}
.primary-color {
  color: #1b70ff;
}
.secondary-color {
  color: green;
}
.accent-color {
  color: blue;
}
.error-color {
  color: #fa5c7c;
}
.success-color {
  color: #0bcf97;
}
.info-color {
  color: #3190ff;
}
.warning-color {
  color: #ffbc02;
}
.grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
}
.grid .ant-card {
  border: none;
  margin: 0;
}
.grid .ant-card .ant-card-body {
  padding: 1rem;
}
.grid.grid-large {
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
}
.grid.grid-normal {
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.grid.grid-small {
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
}
.grid.grid-xs {
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
.flex-grid {
  display: flex;
  gap: 20px;
  grid-gap: 20px;
}
.TaskIssues {
  position: relative;
}
.TaskIssues__create {
  position: absolute;
  top: -40px;
  right: 0;
}
.AnchorsUploadForm {
  text-align: left;
}
.ant-empty .AnchorsUploadForm button[type="submit"] {
  display: block;
  margin: 1rem auto 0;
}
.RequiresActionTabsSmall .ant-table-container {
  max-height: 390px;
  overflow: auto;
}
.RequiresActionPopover {
  min-height: 260px;
  z-index: 1000;
}
.RequiresActionPopover .ant-popover-arrow {
  transform: none;
  left: auto;
  right: 83px;
}
.AnchorEditableDiv {
  margin-bottom: 20px;
}
.bf-content-sm {
  max-height: 65px !important;
}
.AntUploader .ant-upload-list {
  margin-top: -30px;
}
.AntAutoUpload {
  display: none;
}
.popover-single .ant-popover-inner-content {
  padding: 4px 0;
}
.popover-single .ant-row {
  margin: 0;
}
.PriceSettingMinMaxFormula .ant-form-item-control {
  position: relative;
}
.PriceSettingMinMaxFormula .ant-form-item-explain-error {
  left: 0;
  width: 100%;
  bottom: -16px;
}
.PriceSettingMinMaxFormula .ant-form-item-explain-error > div {
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.FilterCheckboxBtn {
  display: flex;
}
.FilterCheckboxBtn .ant-checkbox-wrapper.ant-checkbox-group-item {
  border-radius: 32px;
  font-size: 14px;
  background-color: #fff;
  padding: 5px 22px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: 1px solid #f1f3f6;
}
.FilterCheckboxBtn .ant-checkbox-wrapper.ant-checkbox-group-item.ant-checkbox-wrapper-checked {
  color: #fff;
  background-color: #1b70ff;
  box-shadow: rgba(27, 112, 255, 0.3) 0px 3px 8px 0px;
  border-color: transparent;
}
.FilterCheckboxBtn .ant-checkbox {
  display: none;
}
.NumeratedTextarea,
.AnchorsUploadTextarea {
  padding: 4px 11px;
  padding-left: 39px;
  background-color: transparent;
  color: transparent;
  caret-color: rgba(0, 0, 0, 0.85);
  height: 100% !important;
}
.NumeratedTextarea:empty,
.AnchorsUploadTextarea:empty {
  padding-left: 10px;
}
.NumeratedTextareaWrapper,
.AnchorsUploadWrapper {
  position: relative;
  flex: 1;
  margin-bottom: 10px;
}
.NumeratedTextareaWrapper .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.AnchorsUploadWrapper .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.NumeratedTextareaWrapper .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.AnchorsUploadWrapper .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: transparent !important;
}
.AnchorManager__card .ant-table-thead .ant-checkbox-inner {
  margin-top: -15px;
}
.NumeratedTextareaBg,
.AnchorsUploadDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f1f3f6;
  overflow: auto;
  padding: 4px 11px;
  counter-reset: anchor-increment;
  pointer-events: none;
  scrollbar-width: none;
}
.NumeratedTextareaBg::-webkit-scrollbar,
.AnchorsUploadDiv::-webkit-scrollbar {
  display: none;
}
.NumeratedTextareaBg > div,
.AnchorsUploadDiv > div {
  position: relative;
  padding-left: 28px;
  min-height: 22px;
}
.NumeratedTextareaBg > div > pre,
.AnchorsUploadDiv > div > pre {
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  color: inherit;
  white-space: pre-wrap;
  word-wrap: break-word;
}
.NumeratedTextareaBg > div:nth-child(odd),
.AnchorsUploadDiv > div:nth-child(odd) {
  background-color: #fff;
}
.NumeratedTextareaBg > div:nth-child(even),
.AnchorsUploadDiv > div:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
.NumeratedTextareaBg > div::before,
.AnchorsUploadDiv > div::before {
  counter-increment: anchor-increment;
  content: counter(anchor-increment) ". ";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.65;
  color: rgba(0, 0, 0, 0.85);
}
.NumeratedTextareaBg .invalid-char,
.AnchorsUploadDiv .invalid-char {
  color: #fa5c7c;
}
.ant-select-selector .ant-select-selection-item .SelectOptionRefPageTitle {
  display: flex;
  line-height: 30px;
  height: 30px;
  width: 100%;
  align-items: center;
}
.nav-link:hover {
  color: inherit;
}
.DescriptionEditor .ant-form-item-has-error + .bf-container .bf-content {
  border-color: #ff4d4f;
}
.ant-table-thead > tr > th.column-no-pad,
.ant-table-tbody > tr > td.column-no-pad {
  padding: 0;
  width: 30px;
  text-align: right;
}
.TabsWithIssues.TabsWithIssuesError .ant-tabs-tab-btn[id$="-tab-issues"] {
  color: #fa5c7c;
}
.TabsWithIssues.TabsWithIssuesError .ant-tabs-tab-btn[id$="-tab-issues"] .Ico__icon {
  color: #fa5c7c;
}
.TabsWithIssues.TabsWithIssuesError .ant-tabs-tab-btn[id$="-tab-issues"] .ant-badge-count {
  background-color: #fa5c7c;
  box-shadow: 0 2px 4px rgba(250, 92, 124, 0.3) !important;
}
.TabsWithIssues.TabsWithIssuesError.TabsWithIssuesErrorCurrent .ant-tabs-ink-bar-animated {
  background-color: #fa5c7c;
}
.RefDomainsTable .ant-checkbox {
  margin-left: 15px;
}
.RefLinksTable .ant-checkbox {
  margin-left: 15px;
}
.RefLinksTable th .ant-checkbox {
  margin-top: -15px;
}
.shorten-200 {
  width: 300px;
}
.shorten-200 {
  width: 200px;
}
.shorten-200 > .ant-space-horizontal {
  width: 200px;
}
.shorten-200 > .ant-space-horizontal.refDomainCellGroup > .ant-space-item {
  overflow: hidden;
  text-overflow: ellipsis;
}
.shorten-200 > .ant-space-horizontal.refDomainCellGroup > .ant-space-item:first-child {
  flex-shrink: 0;
}
.landingCellGroup {
  width: 250px;
}
.landingCellGroup > .ant-space-item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.landingCellGroup > .ant-space-item:first-child,
.landingCellGroup > .ant-space-item:last-child {
  flex-shrink: 0;
}
.landingCellLink {
  display: block;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.refLinkCellGroup {
  display: block;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.refPageCellLink {
  display: block;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.userContactList {
  margin-bottom: 40px;
}
.userContactList > ul {
  margin-top: 10px;
  list-style-type: none;
}
.userContactList > ul > li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.userContactList button {
  cursor: pointer;
}
.userContactListBtnWrap {
  text-align: right;
}
.phoneInput {
  width: 100%;
}
.phoneInput .ant-input-number-handler-wrap {
  display: none;
}
.userProfileAhrefsCred {
  display: inline-block;
  white-space: nowrap;
  max-width: calc(100% - 30px);
  overflow: hidden;
  vertical-align: bottom;
  text-overflow: ellipsis;
}
.issuesTableNameShorten {
  white-space: normal;
  max-width: 260px;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.TeamAddMembersForm thead .ant-checkbox {
  margin-top: -15px;
}
.CreateCommentsDialog .ant-form-item-explain {
  min-height: 0;
  margin: 0;
}
.CommentsWrap {
  align-self: flex-start;
  margin-top: 20px;
  max-width: 820px;
  width: 100%;
  align-items: start;
}
.CommentItemControls {
  display: flex;
  justify-content: flex-end;
}
.CommentItemsWrap {
  position: relative;
}
.userStackSingleInline > div {
  line-height: 1;
  align-items: end !important;
}
.userStackSingleInlineCenter > div {
  align-items: center !important;
}
.CommentItemsWrap {
  display: grid;
  grid-gap: 24px;
}
.CommentItemsNewComments {
  position: absolute;
  top: calc(100% - 15px);
  left: 50%;
  transform: translateX(-50%);
}
.CommentItem {
  padding: 12px;
  border-radius: 12px;
  border: 1px solid #1b70ff;
}
.CommentItemAddedBy {
  font-size: 14px;
  color: gray;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.CommentItemTitle {
  margin-bottom: 20px;
}
.CommentItemDesc {
  margin-bottom: 20px;
}
.CommentUser {
  top: -2px;
  margin: 0 2px;
}
.CommentUser .UserStackUserName {
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.issueComment {
  padding-left: 40px;
  position: relative;
}
.issueComment::before {
  content: '';
  position: absolute;
  width: 40px;
  left: 20px;
  height: 1px;
  background-color: #1b70ff;
  top: 34px;
}
.issueComment::after {
  content: '';
  position: absolute;
  top: -24px;
  left: 20px;
  width: 1px;
  height: calc(100% + 24px);
  background-color: #1b70ff;
}
.issueComment:last-child::after {
  height: 58px;
}
.CreateTaskRewardSummaryInner p {
  margin-bottom: 10px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CreateTaskRewardSummaryInner p > span {
  font-size: 20px;
  color: #5d82e7;
}
.CreateTaskRewardSummarySurcharges p > span {
  color: #0bcf97;
}
.CreateTaskRewardSummarySurchargeTitle {
  font-size: 20px;
}
.CreateTaskRewardSummarySurchargeBlock {
  margin-bottom: 20px;
  margin-top: 20px;
}
.CreateTaskRewardSummarySurchargeBlockPenalty p > span {
  color: #fa5c7c;
}
.CreateTaskRewardSummarySurchargeBlockTotal p {
  font-size: 26px;
}
.CreateTaskRewardSummarySurchargeBlockTotal p > span {
  font-size: 26px;
}
.CreateTaskRewardSummarySurchargeBlockTotal {
  border-top: 1px solid #252525;
  padding-top: 20px;
}
.CreateTaskRewardSummarySurchargeBlockPenalty {
  padding-top: 20px;
  border-top: 1px solid #252525;
}
.CreateTaskRewardSummarySurchargeBlockTitle {
  padding-bottom: 20px;
  border-bottom: 1px solid #252525;
  margin-top: 0;
}
.CreateTaskRewardSummarySurchargeBlockSubtotal {
  padding-top: 20px;
  border-top: 1px solid #252525;
}
.faqSearch .ant-collapse-content-box a {
  color: #6b89ff;
}
.priceSettingFormBonuses .ant-form-item-label label {
  white-space: normal;
  height: auto;
}
.priceSettingFormBonuses .ant-form-item {
  align-items: center;
}
.TaskRefPageLinkCardBody:hover {
  color: #252525;
}
.TaskRefPageLinkCardBodyUrlLink {
  word-break: break-all;
}
.TaskRefPageLinkCardBodyUrlLink:hover {
  color: #1890ff;
}
.taskRecommendedSubmissionPrice {
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.taskRecommendedSubmissionPrice::after {
  content: '';
  position: absolute;
  left: -12px;
  width: calc(100% + 24px + 3rem);
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  bottom: 0;
}
.taskRewardButton i {
  color: #fff !important;
  background: transparent !important;
  align-self: center !important;
}
.task-card-warning {
  border: 1px solid #FA5C7C;
}
