// Ultimate
.card {
    .light-shadows;
    border-radius: @card-border-radius;
    padding: 0.9rem;
    background: #fff;
    transition: all 0.15s ease;
    word-break: break-word;

    &.ant-card {
        .ant-card-body {
            padding: 0; // clear paddings for ant-cards
        }
    }
}

.card-default {
    .card;
    display: inline-flex;
    flex-flow: column;
    gap: 0.5rem;

    header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        line-height: 1;
        font-size: 1rem;
        font-weight: @weight-bold;
        gap: 1rem;

        div {
            &::first-letter {
                text-transform: uppercase;
            }
        }
    }
    main {
        flex: 1;
        word-break: break-word;
    }
    footer {
        // opacity: 0.5;
        font-size: 0.9rem;
        border-top: 1px dashed rgba(silver, 0.3);
        margin-top: 0.3rem;
        padding-top: 0.3rem;

        &:empty {
            display: none;
        }
    }
    &.card-blue {
        padding: 1rem;
        border: 1px solid rgba(@primary, 0.1);
        box-shadow: 2px 2px 2px rgba(#eaf3ff, 0.5);
        border-radius: @card-border-radius;
        background: #f6faff;
        display: flex;
        flex-flow: column;
        position: relative;
        color: #47698e;
    }
}

.card-xs {
    .light-shadows;
    border-radius: @card-border-radius;
    padding: 0.2rem 0.5rem;
    background: #fff;
}
.card-lg {
   .card;
    padding: 3rem 4rem;
}

.card-active {
    box-shadow: 0 0 0 1px @primary, 0 3px 5px -2px rgba(@primary, 0.1), 0 0 5px 1px rgba(@primary, 0.3) !important;
}

.card-interactive {
    .card;
    .interactive;
}
