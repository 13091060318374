// Tabs navigation
.ant-tabs-nav {
    .ant-tabs-tab-active {
        font-weight: normal;
    }

    .Ico__text {
        margin-left: 0.3rem;
    }
}

// Tabs
.ant-tabs {
    .ant-tabs-tab-btn {
        i {
            color: @primary;
            transition: all 0.2s ease;
            font-size: 18px;
            position: relative;
            top: 2px;
        }
    }
    .ant-tabs-content {
        min-height: 100px;
    }
}

.ChangeDueDatePopover.clickable {
    position: relative;

    .Ico__text {
        time {
            // position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}

