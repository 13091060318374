.primary-color {
    color: @primary;
}

.secondary-color {
    color: @secondary;
}

.accent-color {
    color: @accent;
}

.error-color {
    color: @danger;
}

.success-color {
    color: @success;
}

.info-color {
    color: @info;
}

.warning-color {
    color: @warning;
}
