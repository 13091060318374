/* nunito-300 - latin */
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 300;
    src: local(""), url(/media/fonts/nunito/nunito-v16-latin-300.woff2) format("woff2"),
        url(/media/fonts/nunito/nunito-v16-latin-300.woff) format("woff");
}

/* nunito-regular - latin */
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    src: local(""), url(/media/fonts/nunito/nunito-v16-latin-regular.woff2) format("woff2"),
        url(/media/fonts/nunito/nunito-v16-latin-regular.woff) format("woff");
}

/* nunito-600 - latin */
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 600;
    src: local(""), url(/media/fonts/nunito/nunito-v16-latin-600.woff2) format("woff2"),
        url(/media/fonts/nunito/nunito-v16-latin-600.woff) format("woff");
}

/* nunito-700 - latin */
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    src: local(""), url(/media/fonts/nunito/nunito-v16-latin-700.woff2) format("woff2"),
        url(/media/fonts/nunito/nunito-v16-latin-700.woff) format("woff");
}

/* nunito-800 - latin */
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 800;
    src: local(""), url(/media/fonts/nunito/nunito-v16-latin-800.woff2) format("woff2"),
        url(/media/fonts/nunito/nunito-v16-latin-800.woff) format("woff");
}

/* nunito-900 - latin */
@font-face {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 900;
    src: local(""), url(/media/fonts/nunito/nunito-v16-latin-900.woff2) format("woff2"),
        url(/media/fonts/nunito/nunito-v16-latin-900.woff) format("woff");
}
