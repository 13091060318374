html,
body {
    margin: 0;
    // background: #f6f8fb;
    // background: #f8f9fa;
    background: #fafbfb;
}

body {
    font-family: @font-base;
}

h1 {
    margin-bottom: 1rem;
}

h2,
h3,
h4,
h5,
h6 {
    margin: 1rem 0 0.5rem;
}

ul {
    padding: 0;
    margin: 0;
}

a {
    color: #252525;
}

hr {
    opacity: 0.2;
}

label {
    user-select: none;
}

a.active {
    color: @primary;
}

:focus {
    outline: none;
}

.visible-on-small {
    display: none;

    @media (max-width: 640px) {
        display: block;
    }
}

.subheading {
    margin-bottom: 2rem;
    font-size: 1rem;
}

.currency {
    font-weight: 700;
    white-space: nowrap;

    // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    &[data-value="0"] {
        color: @danger;
    }

    &:before {
        font-weight: 300;

        td & {
            opacity: 0.3;
        }
    }

    &.currency-EUR {
        &:before {
            content: "€ ";
        }
    }

    &.currency-USD {
        &:before {
            content: "$ ";
        }
    }

    &.invert {
        color: #fff;

        &:before {
            color: rgba(white, 0.3);
        }
    }
}

.list-numbered {
    margin: 0;
    padding-left: 2ch;
}

.link {
    position: relative;
    transition: all 0.2s ease;
    color: @primary;

    &:hover {
        border-color: transparent;
    }
}

.upper-first {
    text-transform: lowercase;
    &::first-letter {
        text-transform: uppercase;
    }
}
span.upper-first {
    display: inline-block;
}

.ErrorServer {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Ico {
    &__text {
        margin-left: 0.3rem;
    }
    &__icon {

        each(@tag-variants, {
            &.@{key} {
                background: transparent;
                color: @value;
            }
            &.danger,
            &.success {
                font-weight: 600;
                -webkit-font-smoothing: antialiased;
                text-rendering: auto;
            }
            &.muted {
                color: rgba(black, 0.4);
                font-weight: 100;
            }
        })

        &.inline {
            display: inline;
        }
    }
}

.opacity {
    opacity: 0.5;
}

.space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.flex {
    display: flex;
}
.flex-center {
    display: flex;
    align-items: center;
    gap: 5px;
}

.clickable {
    cursor: pointer;
}

.inactive {
    opacity: 0.5;

    &:hover {
        opacity: 1;
    }
}

.hide {
    display: none !important;
}

.visibility-hidden {
    width: 0;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    position: absolute;
}

.light-shadows {
    box-shadow: 0 4px 14px -4px #1558a809, 0 2px 15px -5px rgba(21, 88, 168, 0.15);
}

.tiny-shadows {
    box-shadow: 0 4px 14px -4px #1558a809, 0 2px 10px -5px rgba(21, 88, 168, 0.25);
}

.text-overflow {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.brand-icons {
    i.fal {
        color: #1890ff;
        background: #e6f7ff;
        border-radius: 50%;
        margin-right: 0.3rem;
        align-self: flex-start;
        min-width: 1ch;
        text-align: center;
        each(@tag-variants, {
            &.@{key} {
                background: rgba($color, 0.1);
                color: @value;
            }
        })
    }
}

.interactive {
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        box-shadow: 0 4px 14px -4px rgba(#366fb4, 0.2), 0 2px 25px -5px rgba(#366fb4, 0.1),
            0 1px 1px rgba(#366fb4, 0.05);
    }

    &:active {
        transform: translateY(1px);
        transition: transform 0.1s;
        box-shadow: 0 1px 3px -1px rgba(21, 88, 168, 0.1);
    }
}

.ant-card-selected {
    border: 1px solid @primary !important;
}

.locked {
    user-select: none;
    pointer-events: none;
}

.disabled {
    opacity: 0.5 !important;
    .locked;
}

.noselect {
    user-select: none;
}

.pull-right {
    float: right;
}

.text-xs {
    font-size: 0.85rem;
    line-height: 1.2;
}

.text-muted {
    opacity: 0.65;
}

.text-strong {
    font-weight: bold;
}

tr > td.cell-xs {
    padding: 0 0.5rem;
}

ul.ant-timeline {
    padding-top: 5px;
    li.ant-timeline-item {
        &:last-child {
            padding-bottom: 0;
            .ant-timeline-item-content {
                min-height: unset; // for popover
            }
        }
        .ant-timeline-item-content {
            padding-top: 2px;
        }
    }
}
