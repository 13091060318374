.TaskIssues {
    position: relative;

    &__create {
        position: absolute;
        top: -40px;
        right: 0;
    }
}

.AnchorsUploadForm {
    text-align: left; // case when use it from ant-empty (text-align:center)
    button[type="submit"] {
        .ant-empty & {
            display: block;
            margin: 1rem auto 0;
        }
    }
}

.RequiresActionTabsSmall {
    .ant-table-container {
        max-height: 390px;
        overflow: auto;
    }
}


.RequiresActionPopover {
    .ant-popover-arrow {
        transform: none;
        left: auto;
        right: 83px;
    }
    min-height: 260px;
    z-index: 1000;
}

.AnchorEditableDiv {
    margin-bottom: 20px;
}

.bf-content-sm {
    max-height: 65px !important;
}

.AntUploader {
    .ant-upload-list {
        margin-top: -30px;
    }
}

.AntAutoUpload {
    display: none;
}

.popover-single {
    .ant-popover-inner-content {
        padding: 4px 0;
    }
    .ant-row {
        margin: 0;
    }
}


.PriceSettingMinMaxFormula {
    .ant-form-item-control {
        position: relative;
    }
    .ant-form-item-explain-error {
        left: 0;
        width: 100%;
        bottom: -16px;
        > div {
            margin-top: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.FilterCheckboxBtn {
    display: flex;

    .ant-checkbox-wrapper.ant-checkbox-group-item {
        border-radius: 32px;
        font-size: 14px;
        background-color: #fff;
        padding: 5px 22px;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        border: 1px solid #f1f3f6;

        &.ant-checkbox-wrapper-checked {
            color: #fff;
            background-color: rgb(27, 112, 255);
            box-shadow: rgba(27, 112, 255, 0.3) 0px 3px 8px 0px;
            border-color: transparent;
        }
    }

    .ant-checkbox {
        display: none;
    }
}

.NumeratedTextarea,
.AnchorsUploadTextarea {
    padding: 4px 11px;
    padding-left: 39px;
    background-color: transparent;
    color: transparent;
    caret-color: rgba(0, 0, 0, 0.85);
    height: 100% !important;

    &:empty {
        padding-left: 10px;
    }
}

.NumeratedTextareaWrapper,
.AnchorsUploadWrapper {
    position: relative;
    flex: 1;
    margin-bottom: 10px;

    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
        background: transparent !important;
    }
}

.AnchorManager__card {
    .ant-table-thead {
        .ant-checkbox-inner {
            margin-top: -15px;
        }
    }
}

.NumeratedTextareaBg,
.AnchorsUploadDiv {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #f1f3f6;
    overflow: auto;
    padding: 4px 11px;
    counter-reset: anchor-increment;
    pointer-events: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    > div {
        position: relative;
        padding-left: 28px;
        min-height: 22px;


        > pre {
            font-family: inherit;
            font-size: inherit;
            margin: 0;
            color: inherit;
            white-space: pre-wrap;
            word-wrap: break-word;
        }

        &:nth-child(odd) {
            background-color: #fff;
        }

        &:nth-child(even) {
            background-color: rgba(black, 0.05);
        }

        &::before {
            counter-increment: anchor-increment;
            content: counter(anchor-increment) ". ";
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.65;
            color: rgba(0, 0, 0, 0.85);
        }
    }

    .invalid-char {
        color: #fa5c7c;
    }
}

.ant-select-selector {
    .ant-select-selection-item {
        .SelectOptionRefPageTitle {
            display: flex;
            line-height: 30px;
            height: 30px;
            width: 100%;
            align-items: center;
        }
    }
}


.nav-link {
    &:hover {
        color: inherit;
    }
}


.DescriptionEditor {
    .ant-form-item-has-error + .bf-container {
        .bf-content  {
            border-color: #ff4d4f;
        }
    }
}

.ant-table-thead > tr > th.column-no-pad, .ant-table-tbody > tr > td.column-no-pad {
    padding: 0;
    width: 30px;
    text-align: right;
}


.TabsWithIssues.TabsWithIssuesError {
    .ant-tabs-tab-btn {
        &[id$="-tab-issues"] {
            color: #fa5c7c;

            .Ico__icon {
                color: #fa5c7c;
            }

            .ant-badge-count {
                background-color: #fa5c7c;
                box-shadow: 0 2px 4px rgba(#fa5c7c, 0.3) !important;
            }
        }
    }

    &.TabsWithIssuesErrorCurrent {
        .ant-tabs-ink-bar-animated {
            background-color: #fa5c7c;
        }
    }
}

.RefDomainsTable {
    .ant-checkbox {
        margin-left: 15px;
    }
}

.RefLinksTable {
    .ant-checkbox {
        margin-left: 15px;
    }

    th {
        .ant-checkbox {
            margin-top: -15px;
        }
    }
}

.shorten-200 {
    width: 300px;

    &.taskCellGroup {

    }
}

.shorten-200 {
    width: 200px;

    > .ant-space-horizontal {
        width: 200px;

        &.refDomainCellGroup {
            > .ant-space-item {
                overflow: hidden;
                text-overflow: ellipsis;

                &:first-child {
                    flex-shrink: 0;
                }
            }
        }


    }
}

.landingCellGroup {
    width: 250px;

    > .ant-space-item {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-child,
        &:last-child {
            flex-shrink: 0;
        }
    }
}

.landingCellLink {
    display: block;
    width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.refLinkCellGroup {
    display: block;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.refPageCellLink {
    display: block;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
}


.userContactList {
    margin-bottom: 40px;
    > ul {
        margin-top: 10px;
        list-style-type: none;
        > li {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
        }
    }

    button {
        cursor: pointer;
    }

    &BtnWrap {
        text-align: right;
    }
}


.phoneInput {
    width: 100%;

    .ant-input-number-handler-wrap {
        display: none;
    }
}


.userProfileAhrefsCred {
    display: inline-block;
    white-space: nowrap;
    max-width: calc(100% - 30px);
    overflow: hidden;
    vertical-align: bottom;
    text-overflow: ellipsis;
}

.issuesTableNameShorten {
    white-space: normal;
    max-width: 260px;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.TeamAddMembersForm {
    thead {
        .ant-checkbox {
            margin-top: -15px;
        }
    }
}


.CreateCommentsDialog {
    .ant-form-item-explain {
        min-height: 0;
        margin: 0;
    }
}

.CommentsWrap {
    align-self: flex-start;
    margin-top: 20px;
    max-width: 820px;
    width: 100%;
    align-items: start;
}

.CommentItemControls {
    display: flex;
    justify-content: flex-end;
}

.CommentItemsWrap {
    position: relative;
}

.userStackSingleInline {

    > div {
        line-height: 1;
        align-items: end !important;
    }

    &Center {
        > div {
            align-items: center !important;
        }
    }
}

.CommentItemsWrap {
    display: grid;
    grid-gap: 24px;
}

.CommentItemsNewComments {
    position: absolute;
    top: calc(100% - 15px);
    left: 50%;
    transform: translateX(-50%);
}


.CommentItem {
    padding: 12px;
    border-radius: 12px;
    border: 1px solid rgb(27, 112, 255);
}

.CommentItemAddedBy {
    font-size: 14px;
    color:  gray;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

}

.CommentItemTitle {
    margin-bottom: 20px;
}

.CommentItemDesc {
    margin-bottom: 20px;
}

.CommentUser {
    top: -2px;
    margin: 0 2px;

    .UserStackUserName {
        max-width: 125px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}


.issueComment {
    padding-left: 40px;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        width: 40px;
        left: 20px;
        height: 1px;
        background-color: #1b70ff;
        top: 34px;
    }

    &::after {
        content: '';
        position: absolute;
        top: -24px;
        left: 20px;
        width: 1px;
        height: calc(100% + 24px);
        background-color: #1b70ff;
    }

    &:last-child {
        &::after {
            height: 58px;
        }
    }

}


.CreateTaskRewardSummaryInner {
     p {
        margin-bottom: 10px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        > span {
            font-size: 20px;
            color: @muted;
        }
    }
}

.CreateTaskRewardSummarySurcharges {
    p > span {
        color: @success;
    }
}

.CreateTaskRewardSummarySurchargeTitle {
    font-size: 20px;
}

.CreateTaskRewardSummarySurchargeBlock {
    margin-bottom: 20px;
    margin-top: 20px;
}


.CreateTaskRewardSummarySurchargeBlockPenalty {
    p > span {
        color: @danger;
    }
}

.CreateTaskRewardSummarySurchargeBlockTotal {
    p {
        font-size: 26px;

        > span {
            font-size: 26px;
        }
    }
}


.CreateTaskRewardSummarySurchargeBlockTotal {
    border-top: 1px solid @gray;
    padding-top: 20px;
}

.CreateTaskRewardSummarySurchargeBlockPenalty {
    padding-top: 20px;
    border-top: 1px solid @gray;
}

.CreateTaskRewardSummarySurchargeBlockTitle {
    padding-bottom: 20px;
    border-bottom: 1px solid @gray;
    margin-top: 0;
}

.CreateTaskRewardSummarySurchargeBlockSubtotal {
    padding-top: 20px;
    border-top: 1px solid @gray;
}


.faqSearch {
    .ant-collapse-content-box {
        a {
            color: #6b89ff;
        }
    }
}

.priceSettingFormBonuses {
    .ant-form-item-label {

        label {
            white-space: normal;
            height: auto;
        }
    }

    .ant-form-item {
        align-items: center;

    }
}


.TaskRefPageLinkCardBody {
    &:hover {
        color: @gray;
    }
}

.TaskRefPageLinkCardBodyUrlLink {
    word-break: break-all;

    &:hover {
        color: #1890ff;
    }
}

.taskRecommendedSubmissionPrice {
    position: relative;
    padding-bottom: 12px;
    margin-bottom: 12px;
    &::after {
        content: '';
        position: absolute;
        left: -12px;
        width: calc(100% + 24px + 3rem);
        height: 1px;
        background-color: rgba(255, 255, 255, 0.3);
        bottom: 0;
    }
}

.taskRewardButton {
    i {
        color: #fff !important;
        background: transparent !important;
        align-self: center !important;
    }
}

.task-card-warning {
    border: 1px solid #FA5C7C;
}
