.grid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));

    .ant-card {
        border: none;
        margin: 0; // clear margins -> for grid gap

        .ant-card-body {
            padding: 1rem;
        }
    }
    &.grid-large {
        grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
    }
    &.grid-normal {
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
    &.grid-small {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }
    &.grid-xs {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }
}

.flex-grid {
    display: flex;
    gap: 20px;
    grid-gap: 20px;
}
