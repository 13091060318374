.ant-card {
    .light-shadows;
    border-radius: @card-border-radius;
    margin-bottom: 15px;
    font-size: 1rem;
    overflow: hidden;

    &.ant-card-bordered {
        border: none; // disable borders globaly!
    }

    &.card-fancy {
        .Ico {
            position: absolute;
            right: 15px;
            bottom: 0;
            font-size: 3rem;
            opacity: 0.4;
        }
        .ant-card-head {
            .ant-btn {
                background: transparent linear-gradient(rgba(white, 0.8), rgba(white, 0.3)) center / 200px;
                box-shadow: -2px -2px 2px 1px rgba(white, 0.2), 2px 2px 4px rgba(black, 0.2);
                color: #fff;
                text-shadow: -1px -1px 0 rgba(black, 0.1);
                border: none;
                &:hover {
                    box-shadow: -4px -4px 8px rgba(white, 0.3), 4px 5px 8px rgba(black, 0.25);
                    background: rgba(white, 0.7) linear-gradient(rgba(white, 0.8), rgba(white, 0.3)) center /
                        200px;
                    color: #4983bd;
                    text-shadow: 1px 1px 0 rgba(white, 1);
                }
            }
        }
        &,
        .ant-card-head {
            color: #fff;
            border-bottom-color: rgba(white, 0.3);
        }
    }

    .card-icon {
        position: absolute;
        right: 15px;
        top: 60px;
        font-size: 3rem;
        opacity: 0.4;
    }

    &.card-green {
        background: #5ae7c5;
        background: @bg-waves, linear-gradient(45deg, #3adcb5, #bac4fe);
        // box-shadow: 0 4px 10px -1px rgba(#5ae7c5, 0.33);
    }

    &.card-red {
        background: #eb3b71;
        background: @bg-waves, linear-gradient(45deg, #eb3b71, #fd7e8a);
        // box-shadow: 0 4px 10px -1px rgba(#e75a85, 0.33);
    }

    &.card-purple {
        background-color: #eb3b71;
        background: @bg-waves, linear-gradient(45deg, #6597ff, #a570f7);
        // box-shadow: 0 4px 10px -1px rgba(#a570f7, 0.33);
    }
    &.card-blue {
        background-color: #70b2f7;
        background: @bg-waves, linear-gradient(45deg, #70b2f7, #788bfc);
        // box-shadow: 0 4px 10px -1px rgba(#70b2f7, 0.33);
    }
    &.card-white {
        &,
        .ant-card-head {
            color: #000;
            border-bottom-color: rgba(@primary, 0.1);
        }
        .ant-card-head {
            .ant-btn {
                background: transparent linear-gradient(rgba(white, 0.8), rgba(@primary, 0.1)) center / 200px;
                box-shadow: -2px -2px 2px 1px rgba(white, 0.2), 2px 2px 4px #6195cc69;
                color: @primary;
                &:hover {
                    box-shadow: -4px -4px 8px rgba(white, 0.3), 4px 5px 8px rgba(#6195cc69, 0.25);
                }
            }
        }
    }
    &.card-black {
        background-color: #c5d8df;
        background: linear-gradient(45deg, #c5d8df, #d7dbe5);
        // box-shadow: 0 4px 10px -1px rgba(#c5d8df, 0.33);
    }

    &.ant-card-small {
        .ant-card-head {
            min-height: unset;
            font-weight: @weight-bold;
            padding-left: 1rem;
        }

        .ant-card-head-title {
            padding: 1rem;
        }
    }
    .ant-card-head-title {
        overflow: visible !important;
    }
}

.ant-card-body {
    .ant-table-thead > tr > th {
        background: transparent;
        font-weight: @weight-bold;
    }
}

.card {
    &.ant-empty {
        margin: 0;
    }
}
