.ant-table {
    border-radius: 5px;
    background: #fff;

    .ant-table-title {
        position: absolute;
        top: -1px;
        left: 0;
        right: 0;
        background-color: @primary;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.4rem;
        will-change: transform;
        border-radius: 5px 5px 0 0;
        padding: 1.5rem 2rem;

        &:empty {
            transition: all 0.3s ease;
            max-height: 0;
            opacity: 0;
            transform: translateY(50%);
        }

        &:not(:empty) {
            transition: all 0.5s ease 0.4s;
            max-height: 60px;
            box-shadow: inset 0 -2px 5px rgba(black, 0.1);
            transform: translateY(-100%);
        }

        .ant-btn {
            border-color: transparent !important;
        }
    }

    th.col-actions {
        height: 50px;
    }

    th.ant-table-cell {
        position: relative;
        background: #fafafa;
        border-bottom: 1px solid #f0f0f0;
        min-height: 44px;

        .ant-table-filter-column-title {
            padding: 5px 8px;
        }
    }

    td.ant-table-selection-column {
        min-width: 32px;
    }

    .ant-table-selection-column {
        position: relative;

        .ant-checkbox-wrapper {
            .overlay;
            .flex-center;
        }
    }

    .ant-form-item-control {
        line-height: 1;

        .editable-cell-value-wrap {
            position: relative;
            padding: 0;

            input {
                border: none;
                font-size: 0.8rem;
                box-shadow: none;
                padding: 0;
                margin: 0;
            }
        }
    }

    .ant-table-tbody {
        .ant-table-row {
            &:last-child {
                td {
                    border: none; // perfect view
                }
            }
        }
        .ant-table-placeholder {
            td {
                border: none !important;
            }
        }
    }

    .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
    }
}

.table-shadowed {
    .light-shadows;
    border-radius: 15px;
    overflow: hidden;

    .ant-table-thead {
        th {
            font-weight: bold;
            font-size: 1rem;
        }
    }
}

.ant-tabs-ink-bar {
    z-index: 0;
}

// Hide empty table titles/headings
th.ant-table-cell {
    &:empty {
        padding: 0 !important;
    }
}

tr.active-row {
    position: relative;
    box-shadow: inset 0 0 0 1px @success, inset 0 -1px 0 1px @success, 0 2px 5px rgba(@success, 0.1);
    background: rgba(@success, 0.05) linear-gradient(rgba(@success, 0.01), rgba(@success, 0.1));
    border-radius: 8px;

    button[disabled] {
        box-shadow: none;
        color: @success;
        animation: fadeIn 0.5s ease;
        font-size: 1.5rem;
        &,
        &:hover {
            background: transparent;
            border-color: transparent !important;
        }
    }

    > td {
        border-bottom: none;
    }
}

.table-condensed {
    .ant-table-row-expand-icon-cell {
        text-align: center;
        padding: 0 !important;
        span {
            display: inline-block;
            position: relative;
            top: 3px;
        }
    }
    td {
        padding: 0 10px !important;
    }
}

.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
    margin: -16px;
}
